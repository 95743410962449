import {MDBBox} from "mdbreact";
// import StarIcon from "../../assets/images/Star.svg";
import StarIcon from "../../assets/icons/pink-star.svg";
import React from "react";
import "./HeadingWrapper.scss";

export const HeadingWrapper = (props) => {
    const {title,icon,textClassName,commonClassName} = props;
    return(
        <MDBBox className={"heading-wrapper-section d-flex align-items-center justify-content-center w-100 "+(commonClassName)}>
            <img src={icon?icon:StarIcon} alt={"Star"} className={"img-fluid"}/>
            <p className={"m-0 sub-title "+(textClassName)}>{title}</p>
            <img src={icon?icon:StarIcon} alt={"Star"} className={"img-fluid"}/>
        </MDBBox>
    );
}

