import {combineReducers} from 'redux'
import ProductReducer from "./ProductReducer";
import CheckoutReducer from "./CheckoutReducer";
import AuthReducer from "./AuthReducer";
import OrderReducer from "./OrderReducer";

import {RESET_STORE} from "../actions";

const appReducer = combineReducers({
	ProductReducer,
	CheckoutReducer,
	AuthReducer,
	OrderReducer
});

// reset the state of a redux store
const rootReducer = (state, action) => {
	if (action.type === RESET_STORE) {
		const { CheckoutReducer } = state;
		state = { CheckoutReducer };
	}
	return appReducer(state, action)
}

export default rootReducer;

