/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderByEnum = "BEST_SELLING" | "IS_AVAILABLE" | "NAME_ASC" | "NAME_DESC" | "PRICE_ASC" | "PRICE_DESC" | "%future added value";
export type ProductByFilterMutationVariables = {|
  categories?: ?$ReadOnlyArray<?string>,
  attributeValues?: ?$ReadOnlyArray<?string>,
  orderBy?: ?OrderByEnum,
  searchTerm?: ?string,
  collectionId?: ?string,
|};
export type ProductByFilterMutationResponse = {|
  +productVariantFilter: ?{|
    +status: ?string,
    +productVariants: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +sku: string,
          +variantId: ?string,
          +isAvailable: ?boolean,
          +getPrice: ?string,
          +getOriginalPrice: ?string,
          +featuredImage: ?string,
          +discountedPrice: ?string,
          +discountPercentage: ?string,
          +isInWishlist: ?boolean,
          +product: {|
            +id: string,
            +name: string,
            +rating: ?number,
            +description: ?string,
            +price: any,
            +weight: ?any,
            +discountedPrice: ?any,
            +masterSku: ?string,
            +featuredImage: ?string,
            +collection: ?{|
              +name: string
            |},
          |},
        |}
      |}>
    |},
  |}
|};
export type ProductByFilterMutation = {|
  variables: ProductByFilterMutationVariables,
  response: ProductByFilterMutationResponse,
|};
*/


/*
mutation ProductByFilterMutation(
  $categories: [String]
  $attributeValues: [String]
  $orderBy: OrderByEnum
  $searchTerm: String
  $collectionId: String
) {
  productVariantFilter(categories: $categories, attributeValues: $attributeValues, orderBy: $orderBy, searchTerm: $searchTerm, collectionId: $collectionId) {
    status
    productVariants {
      edges {
        node {
          id
          sku
          variantId
          isAvailable
          getPrice
          getOriginalPrice
          featuredImage
          discountedPrice
          discountPercentage
          isInWishlist
          product {
            id
            name
            rating
            description
            price
            weight
            discountedPrice
            masterSku
            featuredImage
            collection {
              name
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributeValues"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categories"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collectionId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v5 = [
  {
    "kind": "Variable",
    "name": "attributeValues",
    "variableName": "attributeValues"
  },
  {
    "kind": "Variable",
    "name": "categories",
    "variableName": "categories"
  },
  {
    "kind": "Variable",
    "name": "collectionId",
    "variableName": "collectionId"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "searchTerm",
    "variableName": "searchTerm"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "variantId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAvailable",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "getPrice",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "getOriginalPrice",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "featuredImage",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountedPrice",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPercentage",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInWishlist",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "masterSku",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductByFilterMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ProductVariantFilterMutation",
        "kind": "LinkedField",
        "name": "productVariantFilter",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductVariantNodeConnection",
            "kind": "LinkedField",
            "name": "productVariants",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductVariantNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductVariantNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductNode",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v14/*: any*/),
                          (v22/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CollectionNode",
                            "kind": "LinkedField",
                            "name": "collection",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProductByFilterMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ProductVariantFilterMutation",
        "kind": "LinkedField",
        "name": "productVariantFilter",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductVariantNodeConnection",
            "kind": "LinkedField",
            "name": "productVariants",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductVariantNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductVariantNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductNode",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v14/*: any*/),
                          (v22/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CollectionNode",
                            "kind": "LinkedField",
                            "name": "collection",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4cd54f8c4c5d4c6736d37bc7acaa3cb3",
    "id": null,
    "metadata": {},
    "name": "ProductByFilterMutation",
    "operationKind": "mutation",
    "text": "mutation ProductByFilterMutation(\n  $categories: [String]\n  $attributeValues: [String]\n  $orderBy: OrderByEnum\n  $searchTerm: String\n  $collectionId: String\n) {\n  productVariantFilter(categories: $categories, attributeValues: $attributeValues, orderBy: $orderBy, searchTerm: $searchTerm, collectionId: $collectionId) {\n    status\n    productVariants {\n      edges {\n        node {\n          id\n          sku\n          variantId\n          isAvailable\n          getPrice\n          getOriginalPrice\n          featuredImage\n          discountedPrice\n          discountPercentage\n          isInWishlist\n          product {\n            id\n            name\n            rating\n            description\n            price\n            weight\n            discountedPrice\n            masterSku\n            featuredImage\n            collection {\n              name\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ccf1a565ff73c2ae8b7bd88c0cb82abe';

module.exports = node;
