import React from "react";
import "./CustomToast.scss";
import InfoIcon from "../../assets/icons/toast-info-icon.svg";
import ErrorIcon from "../../assets/icons/toast-error-icon.svg";
import SuccessIcon from "../../assets/icons/toast-success-icon.svg";


export const CustomToast = (props) => {
    const {type,message} = props;
    return(
        <div className={"custom-toast-component d-flex align-items-center"}>
            {
                type === "info" &&
                <img src={InfoIcon} alt="icon"/>
            }
            {
                type === "warn" &&
                <img src={InfoIcon} alt="icon"/>
            }
            {
                type === "error" &&
                <img src={ErrorIcon} alt="icon"/>
            }
            {
                type === "success" &&
                <img src={SuccessIcon} alt="icon"/>
            }
            {
                type === "loading" &&
                <img src={InfoIcon} alt="icon"/>
            }
            <p className={"message"}>{message}</p>
        </div>
    );
}

