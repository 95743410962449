import React, { useEffect, useState } from "react";
import "./Cart.scss";
import "../../assets/common/common_style.scss";
import { MDBBox, MDBModal, MDBModalBody } from "mdbreact";

import logo from "../../assets/images/logo-sidebar.svg";
import close from "../../assets/icons/cross-icon-brown.svg";
import close_redesign from "../../assets/icons/cross-icon-simple-darkgold.svg";
import deleteIcon from "../../assets/icons/DeleteIcon.svg";
import locationIcon from "../../assets/icons/Location-gold.svg";
import minus from "../../assets/icons/minus-icon-white.svg";
import minusMobile from "../../assets/icons/minus-icon-goldsvg.svg";
import plus from "../../assets/icons/Plus-icon-white.svg";
import plusMobile from "../../assets/icons/Plus-icon-gold.svg";
import ProceedToCheckout from "../../assets/images/proceed-to-checkout-btn.svg";
import infoIcon from "../../assets/icons/info-icon.svg";

import { useMediaQuery } from "react-responsive";
import DesktopCartListComponent from "../DesktopCartListComponent/DesktopCartListComponent";
import { useNavigate } from "react-router";
import { CustomButton } from "../CustomButton/CustomButton";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CheckoutLineAddMutation from "../../mutations/CheckoutLineAddMutation";
import cogoToast from "cogo-toast";
import {
  addDataToCheckout,
  setCartItem,
  setCartModalState,
  setCheckoutId,
  updateCart,
  // updateCouponValue,
} from "../../store/actions/CheckoutActions";
import CheckoutLineDeleteMutation from "../../mutations/CheckoutLineDeleteMutation";
import ClearCheckoutMutation from "../../mutations/ClearCheckoutMutation";
import BottomModalComponent from "../BottomModalComponent/BottomModalComponent";
import CheckServiceAvailablityMutation from "../../mutations/CheckServiceAvailablityMutation";
import FeaturedProductImg from "../../assets/images/fabelle-detailchocolate-10-1.png";
import uuid from "react-uuid";
import ProductCard from "../ProductCard/ProductCard";
import { CustomToast } from "../CustomToast/CustomToast";
import {
  sendAddToCartToGA,
  sendRemoveFromCartToGA,
  sendViewCartToGA,
} from "../../utils/GA-Events";
import CustomButtonPrimary from "../CustomButton/CustomButtonPrimary";
// import { proceedToCheckoutGA_II} from "../../utils/GA-Events-II";
import { fbPixel_initiateCheckout } from "../../utils/FB_pixel_events";
import RemovePromoCodeMutation from "../../mutations/RemovePromoCodeMutation";
import CartProductListing from "../CartProductListing/CartProductListing";
import CartMobilePriceListing from "../CartMobilePriceListing/CartMobilePriceListing";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import AvailableDiscountsQuery from "../../queries/AvailableDiscountsQuery";
import { fetchQuery } from "relay-runtime";
import environment from "../../environment";

const Cart = (props) => {
  const {
    isOpen,
    closeModal,
    products,
    cartDetailsFromStore,
    checkoutIdFromStore,
    setCartModalStatusToStore,
    setCartDataToStore,
  } = props;

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const [pinCodeModal, openPinCodeModal] = useState(false);
  const [PinCode, setPinCode] = useState("");
  const [pincodeState, setPincodeState] = useState(null);
  const [availableDiscounts, setAvailableDiscounts] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    if (isOpen === true) {
      let tmp_cart_items = [];
      if (
        cartDetailsFromStore.cartItems &&
        cartDetailsFromStore.cartItems.edges
      ) {
        cartDetailsFromStore.cartItems.edges.map((item, index) => {
          let product = item.node.variant;
          tmp_cart_items[index] = {
            item_id: product.sku,
            item_name: product.product.name,
            currency: "INR",
            item_category:
              product.product && product.product.collection
                ? product.product.collection.name
                : "",
            price: product.getPrice,
            quantity: item.node.quantity,
          };
        });
        if (tmp_cart_items.length > 0) {
          sendViewCartToGA(tmp_cart_items);
        }
      }
      if(!isDesktopOrLaptop) getAvailableDiscounts()
    }
  }, [isOpen]);

  // api ---

  const verifyPinCode = () => {
    let variable = {
      pinCode: PinCode,
    };
    CheckServiceAvailablityMutation(
      variable,
      (response) => {
        if (response.checkServiceAvailability.available !== null) {
          setPincodeState(1);
          openPinCodeModal(false);
          setCartModalStatusToStore(true);
          cogoToast.success(
            <CustomToast
              type={"success"}
              message={response.checkServiceAvailability.message}
            />,
            { position: "top-center" }
          );
        } else {
          setPincodeState(0);
          setPinCode("");
          cogoToast.warn(
            <CustomToast
              type={"warn"}
              message={response.checkServiceAvailability.message}
            />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        console.log(err);
        setPincodeState(0);
        cogoToast.warn(<CustomToast type={"warn"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  const getAvailableDiscounts = () => {
    let variables = {
      listType: "CART",
    };
    fetchQuery(environment, AvailableDiscountsQuery, variables)
        .toPromise()
        .then(
            (response) => {
              if (response.availableDiscounts) {
                setAvailableDiscounts(response.availableDiscounts)
              }
            },
            (err) => {
              console.log(err);
            }
        );
  };

  // end ---

  const pincodeHandler = (value) => {
    setPinCode(value);
  };

  const pinCodeModalHandler = () => {
    setCartModalStatusToStore(false);
    openPinCodeModal(true);
  };

  const closePinCodeModal = () => {
    setCartModalStatusToStore(true);
    openPinCodeModal(false);
  };

  const cartCheckoutSubmission = () => {
    navigate("/checkout", { replace: false });
    if (!isDesktopOrLaptop) {
      initiateCheckoutFbPixel();
      // proceedToCheckoutGA_II(cartDetailsFromStore.totalAmount > 0 ? cartDetailsFromStore.totalWithShipping : cartDetailsFromStore.totalAmount)
    }

    if (isDesktopOrLaptop) {
      closeModal();
    }
  };

  const initiateCheckoutFbPixel = () => {
    let tmp_product_ids = [];

    if (
      cartDetailsFromStore?.cartItems &&
      cartDetailsFromStore?.cartItems.edges?.length > 0
    ) {
      cartDetailsFromStore?.cartItems?.edges.map((item, index) => {
        let product = item.node.variant;
        tmp_product_ids.push(product.sku);
      });
    }
    let data = {
      content_ids: tmp_product_ids,
      content_type: "product_group",
      value:
        cartDetailsFromStore.totalAmount > 0
          ? cartDetailsFromStore.totalWithShipping
          : cartDetailsFromStore.totalAmount,
      currency: "INR",
      num_items: tmp_product_ids.length,
    };

    fbPixel_initiateCheckout(data);
  };

  const removeFromCartGA = (lineItem) => {
    let tmp_variant = lineItem.variant;
    let product_formate = {
      sku: tmp_variant.sku,
      name: tmp_variant.product.name,
      collection:
        tmp_variant.product && tmp_variant.product.collection
          ? tmp_variant.product.collection.name
          : "",
      price: tmp_variant.getPrice,
      quantity: lineItem.quantity,
    };
    sendRemoveFromCartToGA(product_formate);
  };

  const removeItemFromCart = (lineItem) => {
    let variable = {
      checkoutId: checkoutIdFromStore,
      lineId: lineItem.id,
    };
    CheckoutLineDeleteMutation(
      variable,
      (response) => {
        if (response.checkoutLineDelete !== null) {
          let cartObj = {
            checkoutId: response.checkoutLineDelete.checkout.id,
            checkout: response.checkoutLineDelete.checkout,
            cartItems: response.checkoutLineDelete.checkout.cartItems.edges,
          };
          setCartDataToStore(cartObj);
          removeFromCartGA(lineItem);
        } else {
          cogoToast.error(
            <CustomToast type={"error"} message={"Something went wrong"} />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  const addProductRedirectHanlder = () => {
    closeModal();
    navigate("/product-list", { replace: false });
  };
  

  return (
    <div>
      <div className={"cart-component"}>
        <MDBModal
          fullHeight={true}
          isOpen={isOpen}
          animation={"right"}
          position={"right"}
        >
          <MDBModalBody>
            {checkoutIdFromStore !== "" ? (
              <div
                className={"d-flex justify-content-between flex-column"}
                style={{ minHeight: "100vh" }}
              >
                <div>
                  {/* header */}
                  {isDesktopOrLaptop ? (
                    <div
                      className={
                        "modal-component-header d-flex justify-content-between align-items-center"
                      }
                    >
                      <p
                        className={"euphemia-400-24 fabelle-primary-brown mb-0"}
                      >
                        My Cart
                      </p>
                      <img
                        src={close}
                        alt={"alt"}
                        className={"cursor-pointer"}
                        onClick={closeModal}
                      />
                    </div>
                  ) : (
                    <div
                      className={
                        "modal-component-header d-flex justify-content-between  align-items-center"
                      }
                      style={{ zIndex: 1000 }}
                    >
                      <div className="d-flex align-items-center gap-3">
                        <img
                          src={close_redesign}
                          alt={"alt"}
                          className={"mr-2"}
                          onClick={closeModal}
                        />
                        <p
                          className={
                            "euphemia-700-20 fabelle-primary-gold mb-2"
                          }
                        >
                          Your Cart
                        </p>
                      </div>
                      <p
                        className={"euphemia-700-14 fabelle-primary-gold mb-2"}
                      >
                        Step 1/3
                      </p>
                    </div>
                  )}

                  <div style={{ cursor: "pointer" }}>
                    <Swiper
                      modules={[Autoplay]}
                      loop={`true`}
                      autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                      }}
                    >
                      {!isDesktopOrLaptop && availableDiscounts?.length > 0 && availableDiscounts.map((item, index) => (
                            <SwiperSlide key={index}>
                              <div
                                className={
                                  "offer-ribbon py-2 px-1 w-100 text-center"
                                }
                              >
                                <p
                                  className={
                                    "euphemia-700-12 fabelle-primary-white mb-2"
                                  }
                                >
                                  {item.description}
                                  <a
                                    href={`/product-list`}
                                    className={
                                      "euphemia-700-16 text-underline mx-2 "
                                    }
                                  >
                                    CODE: {item.code}
                                  </a>
                                </p>
                              </div>
                            </SwiperSlide>
                          ))}
                    </Swiper>
                  </div>

                  {!isDesktopOrLaptop && (
                    <div>
                      {/*<div*/}
                      {/*	className={'d-flex delivery-detail-section justify-content-between container-side-padding mb-3'}>*/}
                      {/*	{pincodeState === 1 && <div className={'d-flex align-items-center'}>*/}
                      {/*		<img src={locationIcon} className={'icon-24'} alt={'alt'}/>*/}
                      {/*		<div className={'d-flex ml-3'}>*/}
                      {/*			<p className={'trade-con-500-16 fabelle-primary-white mb-0 mr-1'}>Deliver*/}
                      {/*				at</p>*/}
                      {/*			<p className={'trade-con-700-16 fabelle-primary-gold mb-0'}>{PinCode}</p>*/}
                      {/*		</div>*/}
                      {/*	</div>}*/}
                      {/*	{(pincodeState === null || pincodeState === 0) &&*/}
                      {/*		<div className={'d-flex align-items-center'}>*/}
                      {/*			<img src={locationIcon} className={'icon-24'} alt={'alt'}/>*/}
                      {/*			<div className={'d-flex ml-3'}>*/}
                      {/*				<p className={'trade-con-500-16 fabelle-primary-white mb-0 mr-1'}>Where*/}
                      {/*					would*/}
                      {/*					you*/}
                      {/*					like us*/}
                      {/*					to deliver ? </p>*/}
                      {/*				/!*<p className={'trade-con-700-16 fabelle-primary-gold mb-0'}>{PinCode}</p>*!/*/}
                      {/*			</div>*/}
                      {/*		</div>}*/}
                      {/*	<p className={'trade-con-700-16 fabelle-secondary-gold-1 mb-0'}*/}
                      {/*	   onClick={pinCodeModalHandler}>{PinCode !== "" ? "Edit" : "Verify"}</p>*/}
                      {/*</div>*/}
                      {/*<div className={'d-flex justify-content-between container-side-padding pt-3'}>*/}
                      {/*	<p className={'euphemia-400-14 fabelle-grey-shade'}>{cartDetailsFromStore.cartDetailsFromStore && cartDetailsFromStore.cartItems.edges.length + (cartDetailsFromStore.cartItems.edges.length > 1 ? ' items' : 'item')} added*/}
                      {/*		to the cart</p>*/}
                      {/*<p className={'euphemia-400-14 fabelle-primary-gold'}*/}
                      {/*   onClick={clearCartItem}>Clear*/}
                      {/*	All</p>*/}
                      {/*</div>*/}
                    </div>
                  )}

                  {/* product listing	*/}
                  <div className={"product-listing section pt-4 pb-4 pb-lg-0"}>
                    <CartProductListing closeModal={closeModal}/>
                  </div>

                  {/*	 mobile price listing */}

                  {!isDesktopOrLaptop &&
                    cartDetailsFromStore.cartItems &&
                    cartDetailsFromStore.cartItems.edges.length !== 0 && (
                      <CartMobilePriceListing />
                    )}
                </div>
                {/* bottom section */}
                {isDesktopOrLaptop ? (
                  cartDetailsFromStore.cartItems &&
                  cartDetailsFromStore.cartItems.edges.length !== 0 && (
                    <div
                      className={
                        "fabelle-brown-background text-center cart-bottom-section py-3 px-5 "
                      }
                    >
                      {/*<div className={'d-flex justify-content-between mb-4'}>*/}
                      {/*	<p className={'fabelle-primary-white trade-con-500-18 mb-0'}>Shipping Price</p>*/}
                      {/*	<p className={'fabelle-secondary-gold-1 trade-con-500-18 mb-0'}>₹ {cartDetailsFromStore.shippingPrice ? cartDetailsFromStore.shippingPrice : 0}</p>*/}
                      {/*</div>*/}
                      {/*<hr style={{border: "1px solid #fff"}}/>*/}
                      {/*<div className={'d-flex justify-content-between mb-4'}>*/}
                      {/*	<p className={'fabelle-primary-white euphemia-400-20 mb-0'}>Total</p>*/}
                      {/*	<p className={'fabelle-secondary-gold-1 trade-con-500-24 mb-0'}>₹*/}
                      {/*		{*/}
                      {/*			cartDetailsFromStore.totalAmount > 0 ? cartDetailsFromStore.totalWithShipping : cartDetailsFromStore.totalAmount*/}
                      {/*		}*/}
                      {/*		/!*{cartDetailsFromStore.totalWithShipping ? cartDetailsFromStore.totalWithShipping : 0}*!/*/}
                      {/*	</p>*/}
                      {/*</div>*/}
                      {/*<Link to={"/checkout"}>*/}

                      <CustomButtonPrimary
                        theme={"light"}
                        name={"View Cart"}
                        className={"euphemia-700-20 w-100"}
                        clickHandler={() => cartCheckoutSubmission()}
                      />
                      {/*</Link>*/}
                      {/*<div className={'gold-outline-button'}>Proceed to Checkout</div>*/}
                    </div>
                  )
                ) : (
                  <>
                    {/*<div className={'mobile-bottom-button-section container-side-padding'}>*/}

                    {/*	<div className={'d-flex align-items-center justify-content-between'}>*/}
                    {/*		<div>*/}
                    {/*			<p className={'fabelle-secondary-gold-3  trade-con-700-20 line-height-0'}>*/}
                    {/*				₹*/}
                    {/*				{*/}
                    {/*					cartDetailsFromStore.totalAmount > 0 ? cartDetailsFromStore.totalWithShipping : cartDetailsFromStore.totalAmount*/}
                    {/*				}*/}
                    {/*			</p>*/}
                    {/*			{*/}
                    {/*				cartDetailsFromStore.getActualTotal > 750 ?*/}
                    {/*					<p className={'save-text mb-0'}>*/}
                    {/*						save ₹{80 + parseInt(cartDetailsFromStore.offerDiscount) + parseInt(cartDetailsFromStore.discount) }*/}
                    {/*					</p>:*/}
                    {/*					<p className={'save-text mb-0'}>*/}
                    {/*						save ₹{parseInt(cartDetailsFromStore.offerDiscount) + parseInt(cartDetailsFromStore.discount) }*/}
                    {/*					</p>*/}
                    {/*			}*/}
                    {/*		</div>*/}

                    {/*		<div className={'button-element'}>*/}
                    {/*			<CustomButtonPrimary theme={'light'} name={"Checkout"}*/}
                    {/*								 className={'euphemia-700-20'}*/}
                    {/*								 disabled={cartDetailsFromStore.cartItems && cartDetailsFromStore.cartItems.edges.length === 0}*/}
                    {/*								 clickHandler={() => cartCheckoutSubmission()}/>*/}
                    {/*		</div>*/}

                    {/*		/!*<p className={'mb-0 fabelle-secondary-gold-1'}>Checkout</p>*!/*/}
                    {/*	</div>*/}

                    {/*</div>*/}
                    {cartDetailsFromStore.cartItems.edges.length > 0 && (
                      <div
                        className={
                          "action-btn-section d-flex justify-content-between align-items-center"
                        }
                      >
                        <div>
                          <p className={"mb-0 checkout-total-text"}>
                            ₹
                            {cartDetailsFromStore.totalAmount > 0
                              ? cartDetailsFromStore.totalWithShipping
                              : cartDetailsFromStore.totalAmount}
                          </p>
                          {cartDetailsFromStore.getActualTotal > 750 ? (
                            <p className={"checkout-discount-text  mb-0"}>
                              save ₹
                              {80 +
                                parseInt(cartDetailsFromStore.offerDiscount) +
                                parseInt(cartDetailsFromStore.discount)}
                            </p>
                          ) : ( (parseInt(cartDetailsFromStore.offerDiscount) +
                          parseInt(cartDetailsFromStore.discount) ) !==  0 &&
                            <p className={"checkout-discount-text  mb-0"}>
                              save ₹
                              {parseInt(cartDetailsFromStore.offerDiscount) +
                                parseInt(cartDetailsFromStore.discount)}
                            </p>
                          )}
                        </div>

                        <CustomButtonPrimary
                          reduceWidth={true}
                          theme={"light"}
                          name={"Checkout"}
                          className={"fabelle-primary-brown euphemia-700-20"}
                          disabled={
                            cartDetailsFromStore.cartItems &&
                            cartDetailsFromStore.cartItems.edges.length === 0
                          }
                          clickHandler={() => cartCheckoutSubmission()}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div>
                {isDesktopOrLaptop ? (
                  <div
                    className={
                      "modal-component-header d-flex justify-content-between align-items-center"
                    }
                  >
                    <p className={"euphemia-400-24 fabelle-primary-brown mb-0"}>
                      My Cart
                    </p>
                    <img
                      src={close}
                      alt={"alt"}
                      className={"cursor-pointer"}
                      onClick={closeModal}
                    />
                  </div>
                ) : (
                  <div
                    className={
                      "modal-component-header d-flex justify-content-between  align-items-center"
                    }
                    style={{ zIndex: 1000 }}
                  >
                    <div className="d-flex align-items-center ">
                      <img
                        src={close_redesign}
                        alt={"alt"}
                        className={"mr-2"}
                        onClick={closeModal}
                      />
                      <p
                        className={"euphemia-700-20 fabelle-primary-gold mb-2"}
                      >
                        Your Cart
                      </p>
                    </div>
                    <p className={"euphemia-700-14 fabelle-primary-gold mb-2"}>
                      Step 1/3
                    </p>
                  </div>
                )}
                <div
                  className={
                    "w-100 d-flex flex-column align-items-center justify-content-center p-3"
                  }
                >
                  <p className={"euphemia-700-16 mb-4 fabelle-primary-brown "}>
                    Your cart is Empty
                  </p>
                  <p
                    className={
                      "euphemia-400-16 mb-2 fabelle-primary-brown text-center mb-5"
                    }
                    style={{ opacity: "0.5" }}
                  >
                    Looks like you have not added any products to the cart. Go
                    ahead and explore Fabelle chocolates
                  </p>
                  {/*{*/}
                  {/*	!isDesktopOrLaptop ?*/}
                  <div className={"w-100 d-flex justify-content-center"}>
                    <CustomButtonPrimary
                      theme={"light"}
                      reduceWidth={true}
                      className={"euphemia-700-16 "}
                      name={"Add Products"}
                      clickHandler={addProductRedirectHanlder}
                    />
                  </div>

                  {/*:*/}
                  {/*		null*/}
                  {/*}*/}
                </div>
              </div>
            )}
          </MDBModalBody>
        </MDBModal>
      </div>
      <div className={"delivery-modal-cart-component"}>
        <BottomModalComponent
          isOpen={pinCodeModal}
          closeModal={closePinCodeModal}
          title={"Delivery Address"}
        >
          <div className={"delivery-modal-section p-3"}>
            <div
              className={
                "redesigned-input-style-gold mb-2 p-3 d-flex align-items-center"
              }
            >
              <input
                maxLength={6}
                onChange={(e) => pincodeHandler(e.target.value)}
                className={"w-100"}
              />
              <p
                className={"euphemia-700-16 fabelle-primary-brown mb-0"}
                onClick={verifyPinCode}
              >
                Apply
              </p>
            </div>
            {pincodeState === 0 && (
              <div className={"d-flex align-items-center"}>
                <img src={infoIcon} alt={"alt"} className={"icon-20"} />
                <p className={"euphemia-400-14 fabelle-primary-white mb-0"}>
                  We are not Available at your location now,
                </p>
              </div>
            )}
          </div>
        </BottomModalComponent>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartDetailsFromStore: state.CheckoutReducer.checkoutData,
  checkoutIdFromStore: state.CheckoutReducer.checkoutId,
});

const mapDispatchToProps = (dispatch) => ({
  setCartModalStatusToStore: (data) => dispatch(setCartModalState(data)),
  setCartDataToStore: (data) => dispatch(updateCart(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
