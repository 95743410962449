import {
	ACTIVE_SINGLE_PRODUCT_DATA,
	ACTIVE_SINGLE_PRODUCT_ID,
	CATEGORY_LIST,
	SET_SEARCH_TERM,
	SET_SEARCHED_FROM_HOME
} from './index'

export const setActiveSingleProductId = data => {
	return ({
		type: ACTIVE_SINGLE_PRODUCT_ID,
		payload: {
			data: data
		}

	})
}
export const setActiveSingleProductData = data => {
	return ({
		type: ACTIVE_SINGLE_PRODUCT_DATA,
		payload: {
			data: data
		}

	})
}
export const setCategoryList = data => {
	return ({
		type: CATEGORY_LIST,
		payload: {
			data: data
		}

	})
}
export const setSearchTerm = data => {
	return ({
		type: SET_SEARCH_TERM,
		payload: {
			data: data
		}

	})
}
export const setSearchedFromHome = data => {
	return ({
		type: SET_SEARCHED_FROM_HOME,
		payload: {
			data: data
		}

	})
}