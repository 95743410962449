import React from 'react'
import './Sidebar.scss'
import '../../assets/common/common_style.scss'
import {MDBModal, MDBModalBody} from "mdbreact";

import logo from '../../assets/images/logo-sidebar.svg'
import close from '../../assets/icons/cross-icon.svg'

const Sidebar = (props) => {
	const {
		children,
		isOpen,
		closeModal
	} = props
  return (
    <div className={'sidebar-component'}>
      <MDBModal isOpen={isOpen}  animation={'left'} fullHeight={true}>
	      <MDBModalBody>
		      <div className={'modal-component-header d-flex justify-content-between align-items-center'}>
			      <img src={logo} alt={'alt'} className={''}/>
			      <img src={close} alt={'alt'} className={''} onClick={closeModal}/>
		      </div>
		      {children}
	      </MDBModalBody>
      </MDBModal>
    </div>
  )
}

export default Sidebar
