import graphql from "babel-plugin-relay/macro";

const CheckPromoCodeForUserQuery = graphql`
	query CheckPromoCodeForUserQuery($checkoutId: String!) {
        checkPromoCodeForUser(checkoutId:$checkoutId) {           
            promoCode
            promoCodeError
            promoCodeApplicability    
        }
	}
`;

export default CheckPromoCodeForUserQuery;