/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductVariantInfoImageType = "FULL" | "SHORT" | "%future added value";
export type ProductVariantByIdQueryVariables = {|
  variantId?: ?string
|};
export type ProductVariantByIdQueryResponse = {|
  +productVariantById: ?{|
    +id: string,
    +variantId: ?string,
    +isAvailable: ?boolean,
    +getPrice: ?string,
    +getOriginalPrice: ?string,
    +featuredImage: ?string,
    +discountedPrice: ?string,
    +discountPercentage: ?string,
    +sku: string,
    +isInWishlist: ?boolean,
    +product: {|
      +id: string,
      +name: string,
      +rating: ?number,
      +description: ?string,
      +price: any,
      +weight: ?any,
      +discountedPrice: ?any,
      +masterSku: ?string,
      +featuredImage: ?string,
      +otherInformation: ?any,
      +learnMore: ?any,
      +collection: ?{|
        +name: string
      |},
    |},
    +variantImages: $ReadOnlyArray<{|
      +id: string,
      +image: ?string,
      +isFeatured: boolean,
    |}>,
    +faqs: ?$ReadOnlyArray<?{|
      +id: string,
      +question: ?string,
      +answer: ?any,
    |}>,
    +variantInfoImages: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +imageUrl: ?string,
          +videoUrl: ?string,
          +type: ProductVariantInfoImageType,
          +isActive: boolean,
          +sortOrder: number,
        |}
      |}>
    |},
  |}
|};
export type ProductVariantByIdQuery = {|
  variables: ProductVariantByIdQueryVariables,
  response: ProductVariantByIdQueryResponse,
|};
*/


/*
query ProductVariantByIdQuery(
  $variantId: String
) {
  productVariantById(variantId: $variantId) {
    id
    variantId
    isAvailable
    getPrice
    getOriginalPrice
    featuredImage
    discountedPrice
    discountPercentage
    sku
    isInWishlist
    product {
      id
      name
      rating
      description
      price
      weight
      discountedPrice
      masterSku
      featuredImage
      otherInformation
      learnMore
      collection {
        name
        id
      }
    }
    variantImages {
      id
      image
      isFeatured
    }
    faqs {
      id
      question
      answer
    }
    variantInfoImages {
      edges {
        node {
          id
          imageUrl
          videoUrl
          type
          isActive
          sortOrder
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "variantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "variantId",
    "variableName": "variantId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "variantId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAvailable",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "getPrice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "getOriginalPrice",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "featuredImage",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountedPrice",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPercentage",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInWishlist",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "masterSku",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "otherInformation",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "learnMore",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductVariantImageNode",
  "kind": "LinkedField",
  "name": "variantImages",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFeatured",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductVariantFAQNode",
  "kind": "LinkedField",
  "name": "faqs",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "question",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductInfoImageNodeConnection",
  "kind": "LinkedField",
  "name": "variantInfoImages",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductInfoImageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductInfoImageNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isActive",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sortOrder",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductVariantByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductVariantNode",
        "kind": "LinkedField",
        "name": "productVariantById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductNode",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v8/*: any*/),
              (v17/*: any*/),
              (v7/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionNode",
                "kind": "LinkedField",
                "name": "collection",
                "plural": false,
                "selections": [
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductVariantByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductVariantNode",
        "kind": "LinkedField",
        "name": "productVariantById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductNode",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v8/*: any*/),
              (v17/*: any*/),
              (v7/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionNode",
                "kind": "LinkedField",
                "name": "collection",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee53420f2dc070e3c2ffbfff5a19c1c4",
    "id": null,
    "metadata": {},
    "name": "ProductVariantByIdQuery",
    "operationKind": "query",
    "text": "query ProductVariantByIdQuery(\n  $variantId: String\n) {\n  productVariantById(variantId: $variantId) {\n    id\n    variantId\n    isAvailable\n    getPrice\n    getOriginalPrice\n    featuredImage\n    discountedPrice\n    discountPercentage\n    sku\n    isInWishlist\n    product {\n      id\n      name\n      rating\n      description\n      price\n      weight\n      discountedPrice\n      masterSku\n      featuredImage\n      otherInformation\n      learnMore\n      collection {\n        name\n        id\n      }\n    }\n    variantImages {\n      id\n      image\n      isFeatured\n    }\n    faqs {\n      id\n      question\n      answer\n    }\n    variantInfoImages {\n      edges {\n        node {\n          id\n          imageUrl\n          videoUrl\n          type\n          isActive\n          sortOrder\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '94799c1ce078abb695ce3d8f1c59ab8a';

module.exports = node;
