import graphql from "babel-plugin-relay/macro";

const CartListQuery = graphql`
  query CartListQuery($cartId: ID) {
	  cart(cartId:$cartId){
     	id
     	offerDiscount
	    cartItems {
        	edges {
        		node {
            		id
            		quantity            		
            		variant{
						id
						getPrice
						getOriginalPrice
						featuredImage
						discountedPrice
						discountPercentage
						sku
						isAvailable
						isInWishlist
						variantId
						product{
							rating
							name
							description
							price
							discountedPrice
							collection{
								name
							}
						}
					}
            	}
         	}
		}
		totalAmount
		discount
		voucherCode
		getActualTotal
		shippingPrice
		totalWithShipping
		confettiAmount
	    user {
	        username
	    }
    
  	  }
  }
`;

export default CartListQuery;