import graphql from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../environment";

const mutation = graphql`
	mutation CheckoutLineDeleteUsingVariantIdMutation($variantId: ID!,$cartId: ID!) {
		checkoutLineDeleteUsingVariantId(variantId: $variantId,cartId: $cartId) {
			checkout {
	    id
	     cartItems {
        edges {
        node {
            id
            quantity
            variant{
				id
				getPrice
                getOriginalPrice
				featuredImage
				discountedPrice
				discountPercentage
				isAvailable
				variantId
				product{
					name
					rating
					description
					price
					discountedPrice
					}
				}
            }
         }
		}
		totalAmount
		discount
		voucherCode
		getActualTotal
		offerDiscount
		shippingPrice
		totalWithShipping
		confettiAmount
	    user {
	        username
	    }
		}
		}
		}
		`;

export default (data, callback, errCallback) => {
	const variables = {
		variantId: data.variantId,
		cartId: data.cartId,
	};

	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.checkoutLineDeleteUsingVariantId !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			},
		},
	)
}