import React, { useEffect, useState } from "react";
import './SiteMap.scss'
import MainPageLayout from "../../layouts/Mainpage/MainPageLayout";
import { HeadingWrapper } from "../../components/HeadingWrapper/HeadingWrapper";
import CategoryQuery from "../../queries/CategoryQuery";
import CollectionQuery from "../../queries/CollectionQuery";
import environment from "../../environment";
import { fetchQuery } from "relay-runtime";
import { fabelle_chocolates } from "../../utils/constants";
import ProductByFilterMutation from "../../mutations/ProductByFilterMutation";
import cogoToast from "cogo-toast";
import { CustomToast } from "../../components/CustomToast/CustomToast";

const SiteMap = () => {
  const [chocolateTypesList, setChocolateTypesList] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [productVariant_list, setProductVariant_list] = useState([]);

  useEffect(() => {
    // console.log("footer")
    categoryFetch();
    collectionFetch();
    filterProductHandler();
    return () => {};
  }, []);

  const collectionFetch = () => {
    fetchQuery(environment, CollectionQuery)
      .toPromise()
      .then(
        (response) => {
          if (response.collections !== null) {
            setCollectionList(response.collections);
          } else {
            setCollectionList([]);
          }
        },
        (err) => {
          console.log(err);
          setCollectionList([]);
        }
      );
  };

  const categoryFetch = () => {
    fetchQuery(environment, CategoryQuery)
      .toPromise()
      .then(
        (response) => {
          if (
            response.category !== null &&
            response.category.edges.length > 0
          ) {
            // debugger
            let ChocolateTypes = response.category.edges.filter(function (
              category
            ) {
              let category_name = category.node.name.toLowerCase();
              return !(
                category_name.includes("cakes") ||
                category_name.includes("beverages")
              );
            });
            let tempArr = [];
            let tempObj;
            ChocolateTypes.filter((filter) => {
              // debugger
              let chocolateInFooter = fabelle_chocolates;
              //   if (chocolateInFooter.includes(filter.node.name.toLowerCase())) {
              tempObj = {
                page: filter.node.name,
                route: `/product-list/category/${filter.node.id}`,
              };
              tempArr.push(tempObj);
              //   }
            });
            setChocolateTypesList(tempArr);
          } else {
            setChocolateTypesList([]);
          }
        },
        (err) => {
          console.log(err);
          setChocolateTypesList([]);
        }
      );
  };

  const filterProductHandler = () => {
    // debugger
    let data = {
      categories: [],
      attributeValues: [],
      orderBySort: "NAME_ASC",
      searchTerm: "",
      collectionId: "",
    };
    // debugger
    ProductByFilterMutation(
      data,
      (response) => {
        if (
          response.productVariantFilter !== null &&
          response.productVariantFilter.productVariants.edges
        ) {
          let tmp_array = [];
          let gaProductListData = [];
          let tmp_product_variants =
            response.productVariantFilter.productVariants.edges;
          tmp_product_variants.map((item, index) => {
            tmp_array[index] = item.node;
          });
          if (tmp_array.length > 0) {
            tmp_array.map((productVariant, index) => {
              let product = {
                item_id: productVariant.sku,
                item_name: productVariant.product.name,
                currency: "IND",
                discount: productVariant.discountPercentage,
                index: index + 1,
                item_brand: "Fabelle",
                item_category: productVariant?.product?.collection
                  ? productVariant.product.collection.name
                  : "",
                item_list_id: "product_listing",
                item_list_name: "Products List",
                item_variant: productVariant.variantId,
                price: productVariant.getPrice,
                quantity: 1,
              };
              gaProductListData.push(product);
            });
          }
          tmp_array = tmp_array.sort((a, b) => b.isAvailable - a.isAvailable);

          setProductVariant_list(tmp_array);
          // setSearchTermToStore("")
        } else {
          cogoToast.error(
            <CustomToast type={"error"} message={"Something went wrong"} />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  return (
    <div>
      <MainPageLayout activePage={"sitemap"}>
        <div className="p-4">
          <HeadingWrapper title={"Sitemap"} />
          <div className="d-flex flex-wrap py-4">
            <div>
              <p className={"title"}>Products</p>
              <ul>
                {productVariant_list.map((item) => {
                  return (
                    <li key={item.id}>
                      <a href={"/product-detail/" + item.id}>
                        {item.product.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <p className={"title"}>Category</p>
              {chocolateTypesList.map((item) => {
                return (
                  <li key={item.page}>
                    <a href={item.route}>{item.page}</a>
                  </li>
                );
              })}
            </div>
            <div>
              <p className={"title"}>Collection</p>
              {collectionList.map((item) => {
                return (
                  <li key={item.id}>
                    <a href={"/product-list/collection/" + item.id}>
                      {item.name}
                    </a>
                  </li>
                );
              })}
            </div>
            <div>
              <p className={"title"}>Pages</p>
              <div>
                <ul>
                  <li>
                    <a href={"/"}>Home</a>
                  </li>
                  <li>
                    <a href={"/about-us"}>About</a>
                  </li>
                  <li>
                    <a href={"/product-list"}>Product List</a>
                  </li>
                  <li>
                    <a href="/checkout">Checkout</a>
                  </li>
                  <li>
                    <a href="/order-confirmation">Order Confirmation</a>
                  </li>
                  <li>
                    <a href="/boutiques">Boutiques</a>
                  </li>
                  <li>
                    <a href="/track-order">My Order</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                  <li>
                    <a href="/masterclass">Masterclass</a>
                  </li>
                  <li>
                    <a href="/societedechocolat/kids">Kids</a>
                  </li>
                  <li>
                    <a href="/terms-use">Terms Use</a>
                  </li>
                  <li>
                    <a href="/billing-terms-condition">
                      Billing Terms Condition
                    </a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/refund-policy">Refund Policy</a>
                  </li>
                  <li>
                    <a href="/my-address">My Address</a>
                  </li>
                  <li>
                    <a href="/wishlist">Wishlist</a>
                  </li>
                  <li>
                    <a href="/corporate-gifting">Corporate Gifting</a>
                  </li>
                </ul>
                <a href="/sitemap">All Pages</a>
              </div>
            </div>
          </div>
        </div>
      </MainPageLayout>
    </div>
  );
};

export default SiteMap;
