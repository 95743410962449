import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  updateCart,
  // updateCouponValue,
} from "../../store/actions/CheckoutActions";
import ApplyPromoCodeMutation from "../../mutations/ApplyPromoCodeMutation";
import CheckPromoCodeForUserQuery from "../../queries/CheckPromoCodeForUserQuery";
import cogoToast from "cogo-toast";
import { CustomToast } from "../CustomToast/CustomToast";
import RemovePromoCodeMutation from "../../mutations/RemovePromoCodeMutation";

import greenCheck from "../../assets/icons/green-check-icon.svg";
import { useMediaQuery } from "react-responsive";
import confettie from "../../assets/icons/confettie-gif.gif";
import {fetchQuery} from "relay-runtime";
import environment from "../../environment";
import loadingCircle from '../../assets/icons/loading-circle.svg'

const CartMobilePriceListing = (props) => {
  const {
    checkoutIdFromStore,
    checkoutDataFromStore,
    cartDetailsFromStore,
    setCartDataToStore,
    couponCodeFromStore,
      } = props;

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const [couponCode, setCouponCode] = useState("");
  // const [couponCodeAction, setCouponCodeAction] = useState(true);
  const [couponError, setCouponError] = useState("");
  const [couponCodeApplicability, setCouponCodeApplicability] = useState(false);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  let componentConstants;
  let isLogin = localStorage.getItem("token");

  if (isDesktopOrLaptop) {
    componentConstants = {
      couponInputClass: "euphemia-700-20",
      couponInputButtonClass: "euphemia-700-16",
      couponErrorClass: "euphemia-400-14",
    };
  } else {
    componentConstants = {
      couponInputClass: "euphemia-700-16",
      couponInputButtonClass: "euphemia-700-14",
      couponErrorClass: "euphemia-400-12",
    };
  }

  useEffect(() => {
    if(isLogin && couponCodeFromStore){
      checkCouponCodeForUser();
    } else {
      setCouponCode(couponCodeFromStore);
      // setCouponCodeAction(cartDetailsFromStore.discount === 0);
      setCouponError(cartDetailsFromStore.discount > 0 ? "Coupon Applied" : "");
      setCouponCodeApplicability(cartDetailsFromStore.discount > 0);
    }
  }, [couponCodeFromStore,isLogin]);



  const verifyCouponCode = () => {
    if (cartDetailsFromStore.discount > 0) {
      removeCouponCode();      
    } else {
      applyCouponCode();
    }
  };

  const applyCouponCode = () => {
    if (couponCode !== "") {
      let variable = {
        checkoutId: checkoutIdFromStore,
        promoCode: couponCode,
      };
      ApplyPromoCodeMutation(
        variable,
        (response) => {
          if (response.applyPromoCode !== null) {
            const { checkout } = response.applyPromoCode;
            let cartObj = {
              checkoutId: checkout.id,
              checkout: checkout,
              cartItems: checkout.cartItems.edges,
            };
            setCartDataToStore(cartObj);           
            cogoToast.success(
              <CustomToast type={"success"} message={"Coupon Applied"} />,
              { position: "top-center" }
            );
            setCouponError("Coupon Applied");
            // setCouponCodeAction(false);
            setCouponCodeApplicability(true);
          }
        },
        (error) => {
          setCouponError(error?.message);
          // setCouponCodeAction(true);
          setCouponCodeApplicability(false);
          cogoToast.error(<CustomToast type={"error"}
						                               message={error?.message}/>, {position: "top-center"});
        }
      );
    }
  };

  const removeCouponCode = (errorMessage,promoCode) => {
    if (checkoutDataFromStore?.discount !== 0 && (!promoCode?couponCode !== "":true)) {
      let variable = {
        checkoutId: checkoutIdFromStore,
      };
      RemovePromoCodeMutation(
        variable,
        (response) => {
          if (response.removePromoCode !== null) {
            const { checkout } = response.removePromoCode;
            let cartObj = {
              checkoutId: checkout.id,
              checkout: checkout,
              cartItems: checkout.cartItems.edges,
            };
            setCartDataToStore(cartObj);
            setCouponCode("");
            cogoToast.error(
              <CustomToast
                type={"error"}
                message={errorMessage??response.removePromoCode.message}
              />,
              { position: "top-center" }
            );           
            setCouponError("");
            // setCouponCodeAction(true);
            setCouponCodeApplicability(true);
            setIsCouponLoading(false); // this is added to remove coupon code from order summary temporarily
          }
        },
        (error) => {
          // setCouponCodeAction(false);
          setCouponError(error?.message);
          setCouponCodeApplicability(false);
          setIsCouponLoading(false); // this is added to remove coupon code from order summary temporarily
        }
      );
    }
  };

  const checkCouponCodeForUser = () => {
      let variable = {
        checkoutId: checkoutIdFromStore
      };
      fetchQuery(environment, CheckPromoCodeForUserQuery, variable)
			.toPromise()
			.then(response => {
				if (response.checkPromoCodeForUser !== null) {
          const {checkPromoCodeForUser} = response;       
          if(checkPromoCodeForUser.promoCodeApplicability){
            setCouponCode(checkPromoCodeForUser.promoCode);
            // setCouponCodeAction(cartDetailsFromStore.discount === 0);
            setCouponError(checkPromoCodeForUser.promoCodeApplicability ? "Coupon Applied" : checkPromoCodeForUser.promoCodeError);
            setCouponCodeApplicability(checkPromoCodeForUser.promoCodeApplicability);
          }else{
            setIsCouponLoading(true);
            removeCouponCode(checkPromoCodeForUser.promoCodeError,checkPromoCodeForUser.promoCode); // Forcing to remove coupon code from order summary - temporary fix
          }
          
        }
        
			}, err => {
				console.log(err)
			});     
  };

  return (
    <div className="cart-mobile-price-listing">
      <div className={"pt-3 pb-4 mobile-price-listing section mt-3"}>
      {
        isCouponLoading ?
        <div className="d-flex flex-column justify-content-center align-items-center">
            
            <img
                src={loadingCircle}
                className={"loading-asset mb-2"}
                alt={"loading"}
            />
            <p className={"mb-2 loading-title"}>
                Loading..
            </p>
        </div>
        :
        <div>
            <div
              className={
                "coupon-section " +
                (couponError && couponError !== "" ? "mb-1" : "mb-3")
              }
            >
              <div
                // style={{ padding: "14px 20px" }}
                className={
                  " fabelle-white-background border-radius-8 position-relative"
                }
              >
                <input
                  type={"text"}
                  placeholder={"Coupon Code"}
                  value={couponCode}
                  onChange={(e) => {
                    setCouponCode(e.target.value);
                    if(e.target.value === ""){
                      setCouponError("");
                    }
                  }}
                  className={
                    "w-100 border-less-input fabelle-primary-gold  mb-0 " +
                    componentConstants.couponInputClass
                  }
                  readOnly={cartDetailsFromStore.discount > 0}
                  style={{
                    width: '100% !important',
                    padding: '0 20px',
                    height: '50px',
                    borderRadius: '8px'
                  }}
                />
                <p
                  className={
                    "mb-0 cursor-pointer " +
                    componentConstants.couponInputButtonClass
                  }
                  onClick={verifyCouponCode}
                  style={{
                    width: 'fit-content',
                    position: 'absolute',
                    top: '50%',
                    right: '14px',
                    transform: 'translateY(-50%)',
                    zIndex: '1'
                  }}
                >
                  {cartDetailsFromStore.discount > 0 ? "Remove" : "Apply"}
                </p>
              </div>
            </div>
            {couponError && couponError !== "" && (
              <div className="d-flex align-items-center gap-2  mb-3">
                {couponCodeApplicability && (
                  <img src={greenCheck} alt="green-check" />
                )}
                <p
                  className={`${componentConstants.couponErrorClass} ${
                    couponCodeApplicability
                      ? "fabelle-secondary-green"
                      : "fabelle-primary-red"
                  } mb-0`}
                >
                  {couponError}
                </p>
              </div>
            )}
          </div>
      }
       

        <p className={"euphemia-700-14 mb-3"}>Price Details</p>
        <div className={"d-flex justify-content-between"}>
          <p className={"euphemia-400-14 mb-2 fabelle-grey-shade"}>
            Items Total
          </p>
          <p className={"trade-con-700-14 mb-2 "}>
            ₹{" "}
            {cartDetailsFromStore.getActualTotal
              ? cartDetailsFromStore.getActualTotal
              : 0}
          </p>
        </div>
        <div className={"d-flex justify-content-between"}>
          <p className={"euphemia-400-14 mb-2 fabelle-grey-shade"}>
            Offer Discount
          </p>
          <p
            className={
              "trade-con-700-14 mb-2 " +
              (cartDetailsFromStore.offerDiscount
                ? "fabelle-primary-green"
                : "")
            }
            style={{
              color:
                cartDetailsFromStore.offerDiscount > 0 ? "#8AD77D" : "#000000",
            }}
          >
            {cartDetailsFromStore.offerDiscount
              ? "-₹" + cartDetailsFromStore.offerDiscount
              : "₹0"}
          </p>
        </div>
        <div className={"d-flex justify-content-between"}>
          <p className={"euphemia-400-14 mb-2 fabelle-grey-shade"}>
            Coupon Discount
          </p>
          <p
            className={
              "trade-con-700-14 mb-2 " +
              (cartDetailsFromStore.discount ? "fabelle-primary-green" : "")
            }
            style={{
              color: cartDetailsFromStore.discount > 0 ? "#8AD77D" : "#000000",
            }}
          >
            {cartDetailsFromStore.discount
              ? "-₹" + cartDetailsFromStore.discount
              : "₹0"}
          </p>
        </div>
        <div className={"d-flex justify-content-between"}>
          <p className={"euphemia-400-14 mb-2 fabelle-grey-shade"}>Delivery</p>
          <div className={"d-flex align-items-baseline"}>
            {cartDetailsFromStore.getActualTotal > 750 && (
              <del className={"trade-con-700-14 mb-2 mr-1 "}>80</del>
            )}
            {cartDetailsFromStore.shippingPrice === 0 ? (
              <p
                className={"trade-con-700-14 mb-2 "}
                style={{ color: "#8AD77D" }}
              >
                FREE
              </p>
            ) : (
              <p className={"trade-con-700-14 mb-2 "}>
                ₹ {cartDetailsFromStore.shippingPrice}
              </p>
            )}
          </div>
        </div>
        {cartDetailsFromStore.totalAmount > 0 ? (
          <div
            className={
              "d-flex justify-content-between align-items-end total-section pt-2 pb-3"
            }
          >
            <div>
              <p className={"euphemia-700-16 mb-1"}>Grand Total</p>
              <p className={"euphemia-400-12 mb-0 "}>Inclusive of all taxes</p>
            </div>

            <p className={"trade-con-700-16 mb-0 "}>
              ₹{" "}
              {cartDetailsFromStore.totalWithShipping
                ? cartDetailsFromStore.totalWithShipping
                : 0}
            </p>
          </div>
        ) : (
          <div className={"d-flex justify-content-between total-section py-2"}>
            <p className={"trade-con-700-16 mb-0 fabelle-primary-white"}>
              Total
            </p>
            <p className={"trade-con-700-16 mb-0 "}>
              ₹{" "}
              {cartDetailsFromStore.totalAmount
                ? cartDetailsFromStore.totalAmount
                : 0}
            </p>
          </div>
        )}

        {cartDetailsFromStore.confettiAmount > 0 && (
          <div className="cart-confetti-section d-flex justify-content-between align-items-center">
            <img
              src={confettie}
              alt="confettie-icon"
              className="confettie-image"
            />
            <p className="euphemia-700-16 mb-0 text-center">
              ₹{cartDetailsFromStore.confettiAmount ?? 0} saved so far in this order
            </p>
            <img
              src={confettie}
              alt="confettie-icon"
              className="confettie-image"
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  checkoutIdFromStore: state.CheckoutReducer.checkoutId,
  cartDetailsFromStore: state.CheckoutReducer.checkoutData,
  checkoutDataFromStore: state.CheckoutReducer.checkoutData,
  couponCodeFromStore: state.CheckoutReducer.promoCode,
  });

const mapDispatchToProps = (dispatch) => ({
  setCartDataToStore: (data) => dispatch(updateCart(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartMobilePriceListing);
