import {RESET_STORE, SET_USER_DETAIL} from "./index";



export const setUserDetail = (data) => ({
	type: SET_USER_DETAIL,
	payload: {
		data: data
	}
})

// to reset the state of redux store
export const resetStore = () => {
	return {
		type: RESET_STORE
	}
}