import {
	ADD_TO_CART,
	CART_ITEM,
	CART_MODAL, ORDER_DETAILS, PAYMENT_DETAILS,
	SET_BILLING_ADDRESS,
	SET_CHECKOUT_ID,
	SET_EMAIL,
	SET_SHIPPING_ADDRESS,
	CLEARCART,
	UPDATE_CART, COUPON_VALUE
} from "./index";

export const setCheckoutId = (data) => ({
	type: SET_CHECKOUT_ID,
	payload: {data: data}
})

export const addDataToCheckout = (data) => ({
	type: ADD_TO_CART,
	payload: {data: data}
})

export const setCartModalState = (data) => ({
	type: CART_MODAL,
	payload: {data: data}
})

export const setCartItem = (data) => ({
	type: CART_ITEM,
	payload: {data: data}
})

export const setEmail = (data) => ({
	type: SET_EMAIL,
	payload: {data: data}
})

export const setShippingAddress = (data) => ({
	type: SET_SHIPPING_ADDRESS,
	payload: {data: data}
})

export const setBillingAddress = (data) => ({
	type: SET_BILLING_ADDRESS,
	payload: {data: data}
})

export const setOrderDetails = (data) => ({
	type: ORDER_DETAILS,
	payload: {data: data}
})

export const setPaymentDetails = (data) => ({
	type: PAYMENT_DETAILS,
	payload: {data: data}
})

export const clearCart = () => ({
	type: CLEARCART
});

export const updateCart = (data) => ({
	type: UPDATE_CART,
	payload: {data: data}
})
export const updateCouponValue = (couponValue, promoCode) => ({
	type: COUPON_VALUE,
	payload: {
		couponValue: couponValue,
		promoCode: promoCode
	}
})