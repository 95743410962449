export const CHECKOUT_BEGIN = 'CHECKOUT_BEGIN';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';
export const CLEARCHECKOUT = 'CLEARCHECKOUT';

export const checkoutBegin = () => ({
	type: CHECKOUT_BEGIN
});

export const checkoutSuccess = checkout_data => ({
	type: CHECKOUT_SUCCESS,
	payload: {checkout_data}
});

export const checkoutFailure = error => ({
	type: CHECKOUT_FAILURE,
	payload: {error}
});

export const clearCheckout = () => {
	return {type: CLEARCHECKOUT}
};
