import React from 'react'
import {Routes, Route} from 'react-router-dom';
import LoadingComponent from "./components/LoadingComponent/LoadingComponent";
import SiteMap from './pages/SiteMap/SiteMap';
// import HomePage from "./pages/HomePage/HomePage";
// import ProductDetailPage from "./pages/ProductDetailPage/ProductDetailPage";
// import ProductList from "./pages/ProductList/ProductList";
// import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
// import Payment from "./pages/CheckoutPage/Payment";
// import OrderConfirmation from "./pages/OrderConfirmation/OrderConfirmation";
// import BoutiquesList from "./pages/BoutiquesList/BoutiquesList";
// import BoutiqueDetail from "./pages/BoutiqueDetail/BoutiqueDetail";
// import {Test} from "./test";
// import MyOrderPage from "./pages/MyOrderPage/MyOrderPage";
// import MyOrderDetailPage from "./pages/MyOrderDetailPage/MyOrderDetailPage";
// import AboutUs from "./pages/AboutUs/AboutUs";
// import Contact from "./pages/Contact/Contact";
// import Master from "./pages/Master/Master";
// import TermsAndConditions from "./pages/DocumentPages/TermsAndConditions";
// import BillingTermsAndConditions from "./pages/DocumentPages/BillingTermsAndConditions";
// import PrivacyPolicy from "./pages/DocumentPages/PrivacyPolicy";
// import RefundPolicy from "./pages/DocumentPages/RefundPolicy";
// import MyAddress from "./pages/MyAddress/MyAddress";
// import Wish from "./pages/wish/wish";
// import CorporateGiftingPage from "./pages/CorporateGiftingPage/CorporateGiftingPage";

const HomePage = React.lazy(()=>import('./pages/HomePage/HomePage'))
const ProductDetailPage = React.lazy(()=>import('./pages/ProductDetailPage/ProductDetailPage'))
const ProductList = React.lazy(()=>import('./pages/ProductList/ProductList'))
const CheckoutPage = React.lazy(()=>import('./pages/CheckoutPage/CheckoutPage'))
const Payment = React.lazy(()=>import('./pages/CheckoutPage/Payment'))
const OrderConfirmation = React.lazy(()=>import('./pages/OrderConfirmation/OrderConfirmation'))
const BoutiquesList = React.lazy(()=>import('./pages/BoutiquesList/BoutiquesList'))
const BoutiqueDetail = React.lazy(()=>import('./pages/BoutiqueDetail/BoutiqueDetail'))
const MyOrderPage = React.lazy(()=>import('./pages/MyOrderPage/MyOrderPage'))
const MyOrderDetailPage = React.lazy(()=>import('./pages/MyOrderDetailPage/MyOrderDetailPage'))
const AboutUs = React.lazy(()=>import('./pages/AboutUs/AboutUs'))

const Contact = React.lazy(()=>import('./pages/Contact/Contact'))
const Master = React.lazy(()=>import('./pages/Master/Master'))
const TermsAndConditions = React.lazy(()=>import('./pages/DocumentPages/TermsAndConditions'))
const BillingTermsAndConditions = React.lazy(()=>import('./pages/DocumentPages/BillingTermsAndConditions'))
const PrivacyPolicy = React.lazy(()=>import('./pages/DocumentPages/PrivacyPolicy'))
const RefundPolicy = React.lazy(()=>import('./pages/DocumentPages/RefundPolicy'))
const MyAddress = React.lazy(()=>import('./pages/MyAddress/MyAddress'))
const Wish = React.lazy(()=>import('./pages/wish/wish'))
const CorporateGiftingPage = React.lazy(()=>import('./pages/CorporateGiftingPage/CorporateGiftingPage'))


const CombinedRoutes = () => {
	return (
		<div>
			<Routes>
				<Route path={'/'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<HomePage/>
					</React.Suspense>
				}/>
				<Route path={'/product-detail/:id'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<ProductDetailPage/>
					</React.Suspense>
				}/>
				<Route exact path={'/product-list'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<ProductList/>
					</React.Suspense>
				}/>
				<Route exact path={'/product-list/:type/:id'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<ProductList/>
					</React.Suspense>
				}/>
				<Route path={'/checkout'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<CheckoutPage/>
					</React.Suspense>
				}/>
				<Route path={'/payment'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<Payment/>
					</React.Suspense>
				}/>
				<Route path={'/order-confirmation'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<OrderConfirmation/>
					</React.Suspense>
				}/>
				<Route path={'/boutiques'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<BoutiquesList/>
					</React.Suspense>
				}/>
				<Route path={'/boutique-detail/:id'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<BoutiqueDetail/>
					</React.Suspense>
				}/>
				<Route path={'/track-order'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<MyOrderPage/>
					</React.Suspense>
				}/>
				<Route path={'/track-order/:id'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<MyOrderDetailPage/>
					</React.Suspense>
				}/>
						{/*<Route path={'/test'} element={<Test/>}/>*/}
				<Route path={'/about-us'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<AboutUs/>
					</React.Suspense>
				}/>
				<Route path={'/contact'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<Contact/>
					</React.Suspense>
				}/>
				<Route path={'/masterclass'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<Master/>
					</React.Suspense>
				}/>
				<Route path={'/societedechocolat/kids'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<Master/>
					</React.Suspense>
				}/>
				<Route path={'/terms-use'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<TermsAndConditions/>
					</React.Suspense>
				}/>
				<Route path={'/billing-terms-condition'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<BillingTermsAndConditions/>
					</React.Suspense>
				}/>
				<Route path={'/privacy-policy'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<PrivacyPolicy/>
					</React.Suspense>
				}/>
				<Route path={'/refund-policy'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<RefundPolicy/>
					</React.Suspense>
				}/>
				<Route path={'/my-address'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<MyAddress/>
					</React.Suspense>
				}/>
				<Route path={'/wishlist'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<Wish/>
					</React.Suspense>
				}/>
				<Route path={'/corporate-gifting'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<CorporateGiftingPage/>
					</React.Suspense>
				}/>
				<Route path={'/sitemap'} element={
					<React.Suspense fallback={<LoadingComponent/>}>
						<SiteMap/>
					</React.Suspense>
				}/>
						{/*<Route*/}
						{/*	path="/track-order"*/}
						{/*	element={*/}
						{/*		<PrivateRoute>*/}
						{/*			<MyOrderPage/>*/}
						{/*		</PrivateRoute>*/}
						{/*	}*/}
						{/*/>*/}
						{/*<Route*/}
						{/*	path="/track-order/:id"*/}
						{/*	element={*/}
						{/*		<PrivateRoute>*/}
						{/*			<MyOrderDetailPage/>*/}
						{/*		</PrivateRoute>*/}
						{/*	}*/}
						{/*/>*/}
						{/*<Route*/}
						{/*	path="/my-address"*/}
						{/*	element={*/}
						{/*		<PrivateRoute>*/}
						{/*			<MyAddress/>*/}
						{/*		</PrivateRoute>*/}
						{/*	}*/}
						{/*/>*/}
						{/*<Route*/}
						{/*	path="/wishlist"*/}
						{/*	element={*/}
						{/*		<PrivateRoute>*/}
						{/*			<Wish/>*/}
						{/*		</PrivateRoute>*/}
						{/*	}*/}
						{/*/>*/}
					</Routes>
		</div>
	)
}

export default CombinedRoutes



