import {gtag} from 'ga-gtag';

export const sendProductClickToGA = (product) => {
	gtag("event", "select_item", {
		items: product
	});

}
export const sendAddToCartToGA = (product) => {

	if (window.dataLayer) {
		window.dataLayer.push({
			"event": "add_to_cart",
			"ecommerce": {
				"items": [
					{
						item_id: product.sku,
						item_name: product.name,
						currency: "INR",
						item_category: product.collection,
						price: product.price,
						quantity: 1
					}
				]
			}
		});
	}
}
export const sendViewCartToGA = (products) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			"event": "view_cart",
			"ecommerce": {
				"items": products
			}
		});
	}
}
export const sendRemoveFromCartToGA = (product) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			"event": "remove_from_cart",
			"ecommerce": {
				"items": [
					{
						item_id: product.sku,
						item_name: product.name,
						currency: "INR",
						item_category: product.collection,
						price: product.price,
						quantity: product.quantity
					}
				]
			}
		});
	}
}
export const sendProductViewToGA = (product) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			"event": "view_item",
			"ecommerce": {
				"items": [
					{
						item_id: product.sku,
						item_name: product.name,
						currency: "INR",
						item_category: product.collection,
						price: product.price,
						quantity: product.quantity
					}
				]
			}
		});
	}
}
export const sendProductItemsImpressionToGA = (products) => {
	gtag("event", "view_item_list", {
		items: products
	});
}
export const sendAddToWishlistToGA = (product) => {
	console.log('wishlist ga')
	gtag("event", "add_to_wishlist", {
		items: product
	})
}
export const sendBeginCheckoutToGA = (products) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			"event": "begin_checkout",
			"ecommerce": {
				"items": products
			}
		});
	}
}
export const sendPurchaseToGA = (purchaseData) => {

	console.log(purchaseData)
	if (window.dataLayer) {
		window.dataLayer.push({
			"event": "purchase",
			"ecommerce": {
				"purchase": {
					transaction_id: purchaseData.transaction_id,
					affiliation: "Online Store",
					value: purchaseData.total,
					shipping: purchaseData.shippingPrice,
					currency: "INR",
				},
				"items": purchaseData.products
			}
		});
	}
}
export const sendShippingToGA = (shippingData) => {
	gtag("event", "add_shipping_info", {
		shipping_tier: shippingData.shipping_tier,
		coupon: shippingData.coupon,
		items: shippingData.products
	})
}
export const sendPaymentInfoToGA = (products) => {
	gtag("event", "add_payment_info", {
		items: products
	})
}
export const sendViewPromotionsToGA = (promotionName) => {
	gtag("event", "view_promotion", {
		promotion_name: promotionName,
	})
}
export const sendSelectPromotionsToGA = (promotionName) => {
	gtag("event", "select_promotion", {
		promotion_name: promotionName,
	})
}


