/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClearCheckoutMutationVariables = {|
  checkoutId: string
|};
export type ClearCheckoutMutationResponse = {|
  +checkoutClear: ?{|
    +checkout: ?{|
      +id: string,
      +cartItems: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +quantity: number,
            +variant: ?{|
              +id: string,
              +getPrice: ?string,
              +getOriginalPrice: ?string,
              +featuredImage: ?string,
              +discountedPrice: ?string,
              +discountPercentage: ?string,
              +product: {|
                +name: string,
                +description: ?string,
                +price: any,
                +discountedPrice: ?any,
              |},
            |},
          |}
        |}>
      |},
      +totalAmount: ?number,
      +discount: ?number,
      +voucherCode: ?string,
      +getActualTotal: ?number,
      +offerDiscount: ?number,
      +shippingPrice: ?number,
      +totalWithShipping: ?number,
      +confettiAmount: ?number,
      +user: ?{|
        +username: ?string
      |},
    |},
    +message: ?string,
  |}
|};
export type ClearCheckoutMutation = {|
  variables: ClearCheckoutMutationVariables,
  response: ClearCheckoutMutationResponse,
|};
*/


/*
mutation ClearCheckoutMutation(
  $checkoutId: String!
) {
  checkoutClear(checkoutId: $checkoutId) {
    checkout {
      id
      cartItems {
        edges {
          node {
            id
            quantity
            variant {
              id
              getPrice
              getOriginalPrice
              featuredImage
              discountedPrice
              discountPercentage
              product {
                name
                description
                price
                discountedPrice
                id
              }
            }
          }
        }
      }
      totalAmount
      discount
      voucherCode
      getActualTotal
      offerDiscount
      shippingPrice
      totalWithShipping
      confettiAmount
      user {
        username
        id
      }
    }
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "checkoutId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "checkoutId",
    "variableName": "checkoutId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "getPrice",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "getOriginalPrice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "featuredImage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountedPrice",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPercentage",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalAmount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voucherCode",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "getActualTotal",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerDiscount",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingPrice",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalWithShipping",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confettiAmount",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClearCheckoutMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveCart",
        "kind": "LinkedField",
        "name": "checkoutClear",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CartNode",
            "kind": "LinkedField",
            "name": "checkout",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartItemNodeConnection",
                "kind": "LinkedField",
                "name": "cartItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CartItemNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CartItemNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductVariantNode",
                            "kind": "LinkedField",
                            "name": "variant",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductNode",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v20/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClearCheckoutMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveCart",
        "kind": "LinkedField",
        "name": "checkoutClear",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CartNode",
            "kind": "LinkedField",
            "name": "checkout",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartItemNodeConnection",
                "kind": "LinkedField",
                "name": "cartItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CartItemNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CartItemNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductVariantNode",
                            "kind": "LinkedField",
                            "name": "variant",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductNode",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v7/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "291961e3a6f424a88f19afc6c677fcb3",
    "id": null,
    "metadata": {},
    "name": "ClearCheckoutMutation",
    "operationKind": "mutation",
    "text": "mutation ClearCheckoutMutation(\n  $checkoutId: String!\n) {\n  checkoutClear(checkoutId: $checkoutId) {\n    checkout {\n      id\n      cartItems {\n        edges {\n          node {\n            id\n            quantity\n            variant {\n              id\n              getPrice\n              getOriginalPrice\n              featuredImage\n              discountedPrice\n              discountPercentage\n              product {\n                name\n                description\n                price\n                discountedPrice\n                id\n              }\n            }\n          }\n        }\n      }\n      totalAmount\n      discount\n      voucherCode\n      getActualTotal\n      offerDiscount\n      shippingPrice\n      totalWithShipping\n      confettiAmount\n      user {\n        username\n        id\n      }\n    }\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b503a13213d8e270ed29b53ff9f3f0f6';

module.exports = node;
