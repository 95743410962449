import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../environment'

const mutation = graphql`
  mutation CreateCheckoutMutation($quantity:Int!,$variantId:String!) {
	checkoutCreate(quantity:$quantity,variantId:$variantId) {
	    created
		checkout {
	        id
		    cartItems {
	        edges {
	        node {
	            id
	            quantity
	            variant{
					id
					getPrice
	                getOriginalPrice
					featuredImage
					discountedPrice
					discountPercentage
					variantId
					isAvailable
					sku
					product{
						name
						rating
						description
						price
						discountedPrice
						collection{
						   name
						}
						}
					}
	            }
	         }
		}
		totalAmount
		discount
		voucherCode
		getActualTotal
		offerDiscount
		shippingPrice
		totalWithShipping
		confettiAmount
	    user {
	        username
	    }
		}
	}    
  }
`

export default (data, callback, errCallback) => {
	const variables = {
		quantity: data.quantity,
		variantId:data.variantId
	};

	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.checkoutCreate !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			},
		},
	)
}






