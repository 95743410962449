/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveFromWishlistMutationVariables = {|
  variantId: string
|};
export type RemoveFromWishlistMutationResponse = {|
  +removeFromWishlist: ?{|
    +status: ?string,
    +message: ?string,
  |}
|};
export type RemoveFromWishlistMutation = {|
  variables: RemoveFromWishlistMutationVariables,
  response: RemoveFromWishlistMutationResponse,
|};
*/


/*
mutation RemoveFromWishlistMutation(
  $variantId: String!
) {
  removeFromWishlist(variantId: $variantId) {
    status
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "variantId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "variantId",
        "variableName": "variantId"
      }
    ],
    "concreteType": "RemoveFromWishList",
    "kind": "LinkedField",
    "name": "removeFromWishlist",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveFromWishlistMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveFromWishlistMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "421fbc88c96381bf34a6760c15e7f2ac",
    "id": null,
    "metadata": {},
    "name": "RemoveFromWishlistMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveFromWishlistMutation(\n  $variantId: String!\n) {\n  removeFromWishlist(variantId: $variantId) {\n    status\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ef2cdf341217a76678fa8c4cfe2df77';

module.exports = node;
