/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CheckPromoCodeForUserQueryVariables = {|
  checkoutId: string
|};
export type CheckPromoCodeForUserQueryResponse = {|
  +checkPromoCodeForUser: ?{|
    +promoCode: ?string,
    +promoCodeError: ?string,
    +promoCodeApplicability: ?boolean,
  |}
|};
export type CheckPromoCodeForUserQuery = {|
  variables: CheckPromoCodeForUserQueryVariables,
  response: CheckPromoCodeForUserQueryResponse,
|};
*/


/*
query CheckPromoCodeForUserQuery(
  $checkoutId: String!
) {
  checkPromoCodeForUser(checkoutId: $checkoutId) {
    promoCode
    promoCodeError
    promoCodeApplicability
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "checkoutId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "checkoutId",
        "variableName": "checkoutId"
      }
    ],
    "concreteType": "CheckPromoCodeResult",
    "kind": "LinkedField",
    "name": "checkPromoCodeForUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "promoCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "promoCodeError",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "promoCodeApplicability",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckPromoCodeForUserQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckPromoCodeForUserQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5dc88e1a6d4b15a97b5b2d627335aecf",
    "id": null,
    "metadata": {},
    "name": "CheckPromoCodeForUserQuery",
    "operationKind": "query",
    "text": "query CheckPromoCodeForUserQuery(\n  $checkoutId: String!\n) {\n  checkPromoCodeForUser(checkoutId: $checkoutId) {\n    promoCode\n    promoCodeError\n    promoCodeApplicability\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5848ccfeb51096e178b50f51575377b7';

module.exports = node;
