import {init,track} from 'fbq'

const pixelId = '1774102986275618'

export const fbPixel_viewContent =(data)=>{
	init(pixelId)
	track('ViewContent',data)
}

export const fbPixel_addToCart =(data)=>{
	init(pixelId)
	track('AddToCart',data)
}

export const fbPixel_addToWishlist =(data)=>{
	init(pixelId)
	track('AddToWishlist',data)
}

export const fbPixel_initiateCheckout =(data)=>{
	init(pixelId)
	track('InitiateCheckout',data)
}

export const fbPixel_paymentInfo =(data)=>{
	init(pixelId)
	track('AddPaymentInfo',data)
}

export const fbPixel_purchase =(data)=>{
	init(pixelId)
	track('Purchase',data)
}

export const fbPixel_completeRegistration =(data)=>{
	init(pixelId)
	track('CompleteRegistration',data)
}

export const fbPixel_lead =(data)=>{
	init(pixelId)
	track('Lead',data)
}

export const fbPixel_download =(data)=>{
	init(pixelId)
	track('Download',data)
}