import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../environment'

const mutation = graphql`
    mutation RemoveFromWishlistMutation($variantId:String!) {
        removeFromWishlist(variantId: $variantId) {
            status
            message
        }
    }
`

export default (data, callback, errCallback) => {
    const variables = {
        variantId: data.variantId,
    };

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response, err) => {
                if (response.removeFromWishlist !== null) {
                    callback(response)
                } else {
                    errCallback(err[0].message);
                }
            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}






