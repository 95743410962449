import React from 'react'
import './LoginComponent.scss'
import '../../assets/common/common_style.scss'
import checkBoxFilled from "../../assets/icons/checkbox-filled.svg";
import checkBox from "../../assets/icons/checkbox-empty.svg";
import {CustomButton} from "../CustomButton/CustomButton";
import {useMediaQuery} from "react-responsive";
import cogoToast from "cogo-toast";
import {CustomToast} from "../CustomToast/CustomToast";
import CustomButtonPrimary from "../CustomButton/CustomButtonPrimary";
import {useNavigate} from "react-router";

const LoginComponent = (props) => {
	const {
		signInOperation, setMobileNumber,
		mobileNumber, checked, setChecked,closeModal,
		setInputDirty, inputDirty, valid, setValid, setTyping
	} = props
	const isDesktopOrLaptop = useMediaQuery({minWidth: 1224})
	const navigate = useNavigate()

	const handleSubmit = () => {
		if (mobileNumber === "") {
			cogoToast.info(<CustomToast type={"info"}
			                            message={"Please fill valid Mobile Number"}/>, {position: "top-center"});
		} else if (mobileNumber.length < 10) {
			cogoToast.info(<CustomToast type={"info"}
			                            message={"Mobile Number should be 10 digits"}/>, {position: "top-center"});

		}  else {
			signInOperation();
		}
	}

	const handleInpuChange = (e) => {
		setTyping(true)

		if (e.target.value.length < 11) {
			setMobileNumber(e.target.value)
			setInputDirty(inputDirty + 1)
		}
		if (e.target.value.length >= 10 && !valid) {
			// debugger
			setValid(true)
		}
		if (e.target.value.length < 10) {
			setValid(false)
		}
		setTimeout(() => {
			setTyping(false)
		}, 3000)

	}

	const redirectToTerms = () => {
		// closeModal()
		navigate('/terms-use', {replace: false})
	}
	const redirectToPrivacy = () => {
		// closeModal()
		navigate('/privacy-policy', {replace: false})
	}

	return (
		<div className={'login-component mx-auto'}>
			<div className={'text-center'}>
				<p className={'eupehmia-400-24'}
				   style={isDesktopOrLaptop ? {marginBottom: "36px", fontSize: "28px"} : {
					   fontSize: '24px',
					   marginBottom: '16px'
				   }}>Welcome to Fabelle </p>
				{isDesktopOrLaptop && <p className={'euphemia-700-24  mb-2'}>Sign in</p>}
				<p className={'  '
					+ (isDesktopOrLaptop ? 'euphemia-400-14 ' : 'euphemia-400-14')}
				   style={isDesktopOrLaptop ? {marginBottom: "36px !"} : {marginBottom: '32px'}}>Enter phone number to
					continue</p>
				<div className={'address-input redesigned-input-style mb-3'}>
					<p className={'mb-0'}>+91</p>
					<input
						type="number"
						pattern="[0-9]*"
						autoFocus
						maxLength={9}
						value={parseInt(mobileNumber)}
						placeholder={'Phone Number'}
						onKeyDown={(e) => {
							// debugger
							if (e.key === 'Enter') {
								handleSubmit();
							}
						}}
						onChange={(e) => handleInpuChange(e)}
						onInput={(e) => handleInpuChange(e)}
						className={'w-100 fabelle-primary-brown'}
					/>

				</div>
			</div>
			<div className={'d-flex text-left mb-5 align-items-center'}>

				<p className={' mb-0 ' +
					(isDesktopOrLaptop ? 'euphemia-400-14' : 'euphemia-400-12')}>By Continuing, I agree to the
					<span className={'euphemia-700-12'}
					      onClick={redirectToTerms}
					> Terms of Use</span> & <span className={'euphemia-700-12'}
					                             onClick={redirectToPrivacy}
					>Privacy Policy</span></p>
			</div>
			{isDesktopOrLaptop &&
				<div className={' d-flex justify-content-center w-100'}>
					<CustomButtonPrimary theme={'light'} name={"Continue"}

					                     className={'w-100 euphemia-700-20'}
					                     clickHandler={handleSubmit}/>

				</div>}
		</div>
	)
}

export default LoginComponent
