import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../environment'

const mutation = graphql`
 mutation ClearCheckoutMutation($checkoutId:String!){
    checkoutClear(checkoutId:$checkoutId){
         checkout {
    id
     cartItems {
        edges {
        node {
            id
            quantity
            variant{
id
getPrice
                getOriginalPrice
featuredImage
discountedPrice
discountPercentage
product{
name
description
price
discountedPrice
}
}
            }
         }
}
totalAmount
discount
voucherCode
getActualTotal
offerDiscount
shippingPrice
totalWithShipping
confettiAmount
    user {
        username
    }
}
        message
    }
 }
`

export default (data, callback, errCallback) => {
    const variables = {
        checkoutId: data.checkoutId,
    };

    commitMutation(
        environment,
        {
            mutation,
            variables,

            onCompleted: (response, err) => {

                if (response.checkoutClear !== null) {
                    callback(response)
                } else {
                    errCallback(err[0].message);
                }

            },
            onError: err => {

                errCallback(err);
            },
        },
    )
}

