import graphql from 'babel-plugin-relay/macro';
import environment from "../environment";
import {commitMutation} from "react-relay";

const mutation = graphql`
	mutation EmailSubscriptionMutation($email: String!) {
		subscribeByEmail(email: $email) {
		message
		ok
		}
		}
		
	`
export default (data,callback,errCallback) => {
	const variables = {
		email: data.email,
	}
	commitMutation(environment, {
		mutation,
		variables,
		onCompleted: (response, err) => {

			if (response.subscribeByEmail !== null) {
				callback(response)
			} else {
				errCallback(err[0].message);
			}

		},
		onError: err => {

			errCallback(err);
		},
	});
}