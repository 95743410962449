import React, {useEffect, useState} from 'react'
import './MainPageLayout.scss'
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import {useMediaQuery} from "react-responsive";
import ScrollToTop from "../../utils/utilis";

import contactIcon from '../../assets/icons/call_yellow.svg'

const MainPageLayout = (props) => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1025})

    const {
        activePage,
        children,
        hideFooterMobile
    } = props
	const [visibleContactInfo, setVisibleContactInfo] = useState(false);

	return (
        <ScrollToTop>
            <div className={'main-page-layout'}>
                <NavigationBar activePage={activePage}/>
                {children}
                {
                	isDesktopOrLaptop &&
                	<hr className={'golden-border my-0'}/>
                }
                {
                    !isDesktopOrLaptop ?
						<>
							{!hideFooterMobile &&
							<FooterComponent/>}
						</>
						:
						<FooterComponent/>
                }
	            {visibleContactInfo &&
					<div className={'contact-content-card normal'}>
						<div>
							<p className={'euphemia-400-16 fabelle-primary-gold mb-2'}>Telephone:<span><a className={'fabelle-secondary-gold-3'}
							                                                                               href={'tel:1800-309-8994'}>1800-309-8994</a></span></p>
							<p className={'euphemia-400-16 fabelle-primary-gold mb-2'}>Email: <span><a className={'fabelle-secondary-gold-3'}
							                                                                             href="mailto:support@itcstore.in">Support@itcstore.in</a></span></p>
						</div>
					</div>
	            }
	            {
					<div className={'contact-floating-button '+(visibleContactInfo?"highlight":"normal")} onClick={()=>setVisibleContactInfo(!visibleContactInfo)}>
						<img src={contactIcon} alt={'contact-floating'} className={''}/>
					</div>
	            }

                {/*{*/}
                {/*	isDesktopOrLaptop &&*/}
				{/*	<a className={'no-style cursor-pointer'} target={'_blank'} href={"https://deepsense.in"}><div className={'credit-footer text-center'}>*/}
				{/*		<p className={'mb-0 trade-con-500-20 fabelle-primary-white'}>Powered by deepsense.in</p>*/}
				{/*	</div></a>*/}
                {/*}*/}
            </div>
        </ScrollToTop>
    )
}

export default MainPageLayout
