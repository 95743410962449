import React, { useEffect, useRef, useState } from "react";
import "./ProductCardHorizontal.scss";
import "../../assets/common/common_style.scss";

import minus from "../../assets/icons/minus-icon-white.svg";
import minusMobile from "../../assets/icons/minus-icon.svg";
import plus from "../../assets/icons/Plus-icon-white.svg";
import plusMobile from "../../assets/icons/Plus-icon.svg";
import CartIcon from "../../assets/icons/cart-icon.svg";
import pencilIcon from "../../assets/icons/pencilIcon.svg";
import cartIcon_redesign from "../../assets/icons/product-horizontal-cart.svg";
import FeaturedProductImg from "../../assets/images/fabelle-detailchocolate-10-1.png";
import designviewmore from "../../assets/icons/testimonial-mobile-right-arrow.svg";
import singleProductBg from "../../assets/images/single-product-pattern-bg.png";
// import filledRating from "../../assets/icons/product-rating-filled-light.svg";
// import unfilled from "../../assets/icons/product-rating-empty-light.svg";

import { useMediaQuery } from "react-responsive";
import { fetchQuery } from "relay-runtime";
import environment from "../../environment";
import ProductVariantByIdQuery from "../../queries/ProductVariantByIdQuery";
import cogoToast from "cogo-toast";
import CreateCheckoutMutation from "../../mutations/CreateCheckoutMutation";
import CheckoutLineAddMutation from "../../mutations/CheckoutLineAddMutation";
import {
  setActiveSingleProductData,
  setActiveSingleProductId,
} from "../../store/actions/ProductActions";
import {
  addDataToCheckout,
  setCartItem,
  setCartModalState,
  setCheckoutId,
  updateCart,
} from "../../store/actions/CheckoutActions";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import CheckoutLineDeleteMutation from "../../mutations/CheckoutLineDeleteMutation";
import uuid from "react-uuid";
import { useLocation } from "react-router-dom";
import filledRating from "../../assets/icons/product-rating-filled.svg";
import unfilled from "../../assets/icons/product-star-unfilled-brown.svg";
import { CustomToast } from "../CustomToast/CustomToast";
import LinesEllipsis from "react-lines-ellipsis";
// import wishlistActive from "../../assets/icons/wishlist-active-icon.svg";
import wishlistActive from "../../assets/icons/wishlist-active-icon-with-bg.svg";
// import wishlistInactive from "../../assets/icons/wishlist-inactive.svg";
import wishlistInactive from "../../assets/icons/wishlist-inactive-with-bg.svg";
import pencilIconBlack from "../../assets/icons/pencil-outline.svg";
import {
  sendAddToCartToGA,
  sendAddToWishlistToGA,
  sendProductClickToGA,
  sendProductItemSelectToGA,
  sendRemoveFromCartToGA,
} from "../../utils/GA-Events";
import back from "../../assets/icons/golden-back-arrow.svg";
import close_redesign from "../../assets/icons/cross-icon-simple-darkgold.svg";
import { MDBModal, MDBModalBody } from "mdbreact";
import filled_star from "../../assets/icons/filled_star.svg";
import empty_star from "../../assets/icons/empty_star.svg";
import close from "../../assets/icons/cross-icon-brown.svg";
import { CustomButton } from "../CustomButton/CustomButton";
import createOrUpdateReviewMutation from "../../mutations/createOrUpdateReviewMutation";
import RemoveFromWishlistMutation from "../../mutations/RemoveFromWishlistMutation";
import AddToWishlistMutation from "../../mutations/AddToWishlistMutation";
import checkoutLineDeleteUsingVariantIdMutation from "../../mutations/CheckoutLineDeleteUsingVariantIdMutation";
// import {wishlistGA_II} from "../../utils/GA-Events-II";
import {
  fbPixel_addToCart,
  fbPixel_addToWishlist,
} from "../../utils/FB_pixel_events";
import ProductRating from "../ProductRating/ProductRating";
import CartListQuery from "../../queries/CartListQuery";

const ProductCardHorizontal = (props) => {
  const {
    id,
    featuredImage,
    name,
    description,
    rating,
    price,
    originalPrice,
    offer,
    checkoutIdFromStore,
    variantId,
    isAvailable,
    inlineid,
    myReview,
    cartItemFromStore,
    productid,
    productData,
    searchModal,
    setReviewAction,
    refreshCount,
    setRefreshCount,
    reviewAction,
    removeWishlistFromWishlistPage,
    setCartModalStatusToStore,
    isCartOpen,
    showAlert,
    isInWishlist,
    discountedPrice,
    discountPercentage,
    setCartDataToStore,
    removeElement,
    quantity,
    disableAddToCart,
    variantPricePincodeBased
      } = props;
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const [quantity1, setQuantity] = useState(0);
  const [showAction, setShowAction] = useState(false);
  const imageEl = useRef(null);
  const [isOrderPage, setIsOrderPage] = useState(false);
  // const [reviewAction, setReviewAction] = useState(false);
  let parms = useLocation().pathname;

  const isLogin = localStorage.getItem("token");

  useEffect(() => {
    if (cartItemFromStore && cartItemFromStore.length > 0) {
      let tmp_quantity = 0;
      let index = cartItemFromStore.findIndex(
        (item) => item.node.variant.id === id
      );
      if (index > -1) {
        tmp_quantity = cartItemFromStore[index].node.quantity;
        setQuantity(tmp_quantity);
      }
    }
  }, []);

  useEffect(() => {
    if (parms.includes("order")) {
      setIsOrderPage(true);
    }
  }, [parms]);

  useEffect(() => {
    if (cartItemFromStore && cartItemFromStore.length > 0) {
      let tmp_quantity = 0;
      let index = cartItemFromStore.findIndex(
        (item) => item.node.variant.id === id
      );
      if (index > -1) {
        tmp_quantity = cartItemFromStore[index].node.quantity;
        setQuantity(tmp_quantity);
        setShowAction(false);
        if (tmp_quantity > 0) {
          setShowAction(true);
        }
      }
    }else if(cartItemFromStore.length === 0){
			setQuantity(0);
			setShowAction(false)
		}
  }, [cartItemFromStore]);

  // useEffect(() => {
  //
  // }, [variantId?.length > 0 && isAvailable]);

  const createCheckout = () => {
    let variable = {
      quantity: 1,
      variantId: id,
    };
    CreateCheckoutMutation(
      variable,
      (response) => {
        if (response.checkoutCreate !== null) {
          if (showAlert === true) {
            cogoToast.success(
              <CustomToast type={"success"} message={"Added To Cart"} />,
              { position: "top-center" }
            );
          }
            let cartObj = {
              checkoutId: response.checkoutCreate.checkout.id,
              checkout: response.checkoutCreate.checkout,
              cartItems: response.checkoutCreate.checkout.cartItems.edges,
            };
            setCartDataToStore(cartObj);
            addToCartGA(1);
            addToCartFbPixel();
            setTimeout(() => {
              // !isCartOpen && setCartModalStatusToStore(true)
            }, 1000);
        } else {
          cogoToast.error(
            <CustomToast type={"error"} message={"Something went wrong"} />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  const addItemToCart = (quantity) => {
    let variable = {
      checkoutId: checkoutIdFromStore,
      quantity: quantity === 0 ? 1 : quantity,
      variantId: id,
    };
    CheckoutLineAddMutation(
      variable,
      (response) => {
        if (response.checkoutLineAdd !== null) {
          if (showAlert === true) {
            cogoToast.success(
              <CustomToast type={"success"} message={"Added To Cart"} />,
              { position: "top-center" }
            );
          }
            let cartObj = {
              checkoutId: response.checkoutLineAdd.checkout.id,
              checkout: response.checkoutLineAdd.checkout,
              cartItems: response.checkoutLineAdd.checkout.cartItems.edges,
            };
            setCartDataToStore(cartObj);
            addToCartGA(quantity);
          addToCartFbPixel();
          setTimeout(() => {
            // !isCartOpen && setCartModalStatusToStore(true)
          }, 1000);
        } else {
          cogoToast.error(
            <CustomToast type={"error"} message={"Something went wrong"} />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  const redirectToDetailPage = () => {
    let product_formate = {
      sku: productData.sku,
      name: productData.product.name,
      collection:
        productData.product && productData.product.collection
          ? productData.product.collection.name
          : "",
      price: productData.getPrice,
      quantity: 1,
    };

    // debugger
    navigate("/product-detail/" + id, { replace: false });
  };

  const incrementCount = () => {
    let tmp_quantity = quantity1;
    if (tmp_quantity > -1) {
      tmp_quantity = tmp_quantity + 1;
    } else {
      tmp_quantity = 0;
    }

    if (checkoutIdFromStore !== "") {
      addItemToCart(tmp_quantity);
    } else {
      createCheckout();
    }
    setQuantity(tmp_quantity);    
  };

  const removeCartItemUsingVariantId = () => {
    let variable = {
      variantId: id,
      cartId: checkoutIdFromStore,
    };
    checkoutLineDeleteUsingVariantIdMutation(
      variable,
      (response) => {
        if (response.checkoutLineDeleteUsingVariantId !== null) {
            let cartObj = {
              checkoutId: response.checkoutLineDeleteUsingVariantId.checkout.id,
              checkout: response.checkoutLineDeleteUsingVariantId.checkout,
              cartItems:
                response.checkoutLineDeleteUsingVariantId.checkout.cartItems
                  .edges,
            };
            setCartDataToStore(cartObj);
            // removeFromCartGA(lineItem);
          cogoToast.success(
            <CustomToast type={"success"} message={"Removed Successfully"} />,
            { position: "top-center" }
          );
        } else {
          cogoToast.error(
            <CustomToast type={"error"} message={"Something went wrong"} />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };
  const decrementCount = () => {
    let tmp_quantity = quantity1;

    if (tmp_quantity > 1) {
      tmp_quantity = tmp_quantity - 1;
      // if (checkoutIdFromStore !== "") {
      // 	addItemToCart(tmp_quantity)
      // } else {
      // 	createCheckout()
      // }
    } else {
      tmp_quantity = 0;
    }
    // if (isCartOpen) {
    //
    // 	removeElement()
    // } else {
    removeCartItemUsingVariantId();
    // }
    removeFromCartGA();
    setShowAction(false);
    setQuantity(tmp_quantity);
      };
  const removeWishList = (id) => {
    let variable = {
      variantId: id,
    };
    RemoveFromWishlistMutation(
      variable,
      (response) => {
        if (response !== null) {
          if(setRefreshCount){
            setRefreshCount(refreshCount + 1);
          }
          getCartList();
          cogoToast.success(
            <CustomToast
              type={"success"}
              message={response.removeFromWishlist.message}
            />,
            { position: "top-center" }
          );
        } else {
          cogoToast.error(
            <CustomToast type={"error"} message={"Something went wrong"} />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  const addWishList = (id) => {
    let variable = {
      variantId: id,
    };
    AddToWishlistMutation(
      variable,
      (response) => {
        if (response !== null) {
          if(setRefreshCount){
            setRefreshCount(refreshCount + 1);
          }
          // wishlistGA_II(productData.getPrice)
          addToWishlistFbPixel();
          let product_formate = {
            item_id: productData.sku,
            item_name: productData.product.name,
            currency: "INR",
            discount: productData.discountPercentage,
            item_brand: "Fabelle",
            item_category: productData?.product?.collection
              ? productData.product.collection.name
              : "",
            item_variant: productData.variantId,
            price: productData.getPrice,
            quantity: 1,
          };
          sendAddToWishlistToGA(product_formate);
          getCartList();
          cogoToast.success(
            <CustomToast
              type={"success"}
              message={response.addToWishlist.message}
            />,
            { position: "top-center" }
          );
        } else {
          cogoToast.error(
            <CustomToast type={"error"} message={"Something went wrong"} />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  const wishlistHandler = (id) => {
    if (productData.isInWishlist || isInWishlist) {
      if (pathname.includes("/wishlist")) {
        removeWishlistFromWishlistPage(id);
      } else {
        removeWishList(id);
      }
    } else {
      addWishList(id);
    }
  };

  const addToWishlistFbPixel = () => {
    let data = {
      content_ids: productData.sku,
      content_type: "product_group",
      value: productData.getPrice,
      currency: "INR",
      num_items: 1,
      content_name: productData.product.name,
      content_category:
        productData.product && productData.product.collection
          ? productData.product.collection.name
          : "",
    };
    fbPixel_addToWishlist(data);
  };

  const addToCartGA = (qty) => {
    let product_formate = {
      sku: productData.sku,
      name: productData.product.name,
      collection:
        productData.product && productData.product.collection
          ? productData.product.collection.name
          : "",
      price: productData.getPrice,
      quantity: qty,
    };
    sendAddToCartToGA(product_formate);
    // addToCartGA_II(productData.getPrice)
  };

  const addToCartFbPixel = () => {
    let data = {
      content_ids: productData.sku,
      content_type: "product_group",
      value: productData.getPrice,
      currency: "INR",
      num_items: 1,
      content_name: productData.product.name,
      content_category:
        productData.product && productData.product.collection
          ? productData.product.collection.name
          : "",
    };
    fbPixel_addToCart(data);
  };
  const removeFromCartGA = () => {
    let product_formate = {
      sku: productData.sku,
      name: productData.product.name,
      collection:
        productData.product && productData.product.collection
          ? productData.product.collection.name
          : "",
      price: productData.getPrice,
      quantity: 1,
    };
    sendRemoveFromCartToGA(product_formate);
  };

  const openQtyAndAddToCart = () => {
    incrementCount();
    setShowAction(true);
  };

  const selectEventToGa = () => {
    let product_formate = {
      item_id: productData.sku,
      item_name: productData.product.name,
      currency: "INR",
      discount: productData.discountPercentage,
      item_brand: "Fabelle",
      item_category: productData?.product?.collection
        ? productData.product.collection.name
        : "",
      item_variant: productData.variantId,
      price: productData.getPrice,
      quantity: 1,
    };
    sendProductClickToGA(product_formate);
  };

  const getCartList = () => {
    let variables = {
      cartId: checkoutIdFromStore,
    };
    fetchQuery(environment, CartListQuery, variables)
        .toPromise()
        .then(
            (response) => {
              if (response.cart !== null && response.cart.id) {
                const { cart } = response;
                let cartObj = {
                  checkoutId: cart.id,
                  checkout: cart,
                  cartItems: cart.cartItems.edges,
                };
                setCartDataToStore(cartObj);
              }
            },
            (err) => {
              console.log(err);
            }
        );
  };

  
  return (
    <div
      className={"product-card-horizontal-component"}
      onClick={redirectToDetailPage}
    >
      {!isOrderPage && isLogin && (
        <img
          src={
            productData.isInWishlist || isInWishlist
              ? wishlistActive
              : wishlistInactive
          }
          alt={"alt"}
          className={"icon-24 wishlist-icon"}
          onClick={(e) => {
            e.stopPropagation();
            wishlistHandler(id);
          }}
        />
      )}
      <div className={"card-content-wrap d-flex"}>
        <div
          className={"image-section"}
          // style={{backgroundImage: `url(${singleProductBg})`}}
          //  onClick={() => redirectToDetailPage()}
        >
          <img
            src={featuredImage}
            className={"featured-image"}
            alt={"alt"}
            ref={imageEl}
            // onError={() => {
            //     imageEl.current.src = FeaturedProductImg;
            // }}
          />
        </div>
        <div
          className={" d-flex flex-column  content-section w-100"}
          style={{ padding: "10px" }}
        >
          <div>
            <p
              className={"euphemia-400-14 wrap-in-two-lines mb-1"}
              // onClick={() => redirectToDetailPage()}
            >
              {name}

              {/*<LinesEllipsis*/}
              {/*	text={name}*/}
              {/*	maxLine='1'*/}
              {/*	ellipsis='...'*/}
              {/*	trimRight*/}
              {/*	basedOn='letters'*/}
              {/*/>*/}
            </p>

            <p
              className={"euphemia-400-12  mb-1"}
              // onClick={() => redirectToDetailPage()}
            >
              <LinesEllipsis
                text={description}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </p>
          </div>
          <div className={"d-flex align-items-center"}>
            <ProductRating rating={rating} />
          </div>
          {/*{rating && <div className={'d-flex mb-2 mt-1'}>*/}
          {/*	/!* filled stars*!/*/}
          {/*	{*/}
          {/*		Array(Math.round(rating)).fill(0).map(item => {*/}
          {/*			return <img key={uuid()} alt={'alt'} style={{width: "14px"}} src={filledRating}/>*/}
          {/*		})*/}
          {/*	}*/}
          {/*	/!*unfilled stars*!/*/}
          {/*	{*/}
          {/*		5 - Math.round(rating) > 0 && Array(5 - Math.round(rating)).fill(0).map(item => {*/}
          {/*			return <img key={uuid()} alt={'alt'} style={{width: "14px"}} src={unfilled}/>*/}
          {/*		})*/}
          {/*	}*/}
          {/*</div>}*/}
          <div className={"w-100"}>
            <div className="d-flex justify-content-between align-items-center">
              <div className={"d-flex mb-2 align-items-center"}>
                <p
                  className={"trade-con-700-20 fabelle-primary-brown mb-0 mr-2"}
                >
                  ₹ {((variantPricePincodeBased && parseFloat(variantPricePincodeBased)>0.0)?parseFloat(variantPricePincodeBased):price) * quantity}
                </p>
                {(!variantPricePincodeBased || parseInt(variantPricePincodeBased) === 0 ) && parseInt(discountPercentage) > 0 && (
                  <del
                    className={"trade-con-700-14 fabelle-primary-brown mb-0"}
                  >
                    ₹ {parseInt(originalPrice) * quantity}
                  </del>
                )}
              </div>
              {quantity1 > 0 && disableAddToCart === true && <div>
                <p
                  className={
                    "fabelle-primary-brown opacity-60 mb-0 " +
                    (isDesktopOrLaptop
                      ? "trade-con-700-14"
                      : "trade-con-700-12")
                  }
                >
                  Qty {quantity1}
                </p>
              </div>}
            </div>

          <div
              className={
                "d-flex align-items-center w-100 " +
                (parseInt(discountPercentage) > 0
                  ? "justify-content-between"
                  : "justify-content-end")
              }
            >
              {!variantPricePincodeBased &&  <div
                className={`golden-bg ${
                  parseInt(discountPercentage) > 0 ? "" : "display-none"
                }`}
              >
                <p
                  className={"trade-con-700-12 fabelle-primary-brown p-1 mb-0"}
                >
                  {discountPercentage}%Off
                </p>
              </div>}

              {(!isOrderPage || searchModal) && (
                <>
                  {variantId?.length > 0 && isAvailable ? (
                    <>
                      {showAction === true ? (
                        !disableAddToCart && (
                          <div
                            className={
                              "border-box border-radius-0 d-flex justify-content-between align-items-center " +
                              (isDesktopOrLaptop ? " p-2" : "px-2 py-1")
                            }
                          >
                            <img
                              src={isDesktopOrLaptop ? minus : minusMobile}
                              className={
                                isDesktopOrLaptop ? "icon-24" : "icon-16"
                              }
                              alt={"alt"}
                              onClick={(e) => {
                                e.stopPropagation();
                                decrementCount(variantId);
                              }}
                            />
                            <p
                              className={
                                "fabelle-primary-brown mb-0 " +
                                (isDesktopOrLaptop
                                  ? "trade-con-700-24"
                                  : "trade-con-700-16")
                              }
                            >
                              {quantity1}
                            </p>
                            <img
                              src={isDesktopOrLaptop ? plus : plusMobile}
                              className={
                                isDesktopOrLaptop ? "icon-24" : "icon-16"
                              }
                              alt={"alt"}
                              onClick={(e) => {
                                e.stopPropagation();
                                incrementCount();
                              }}
                            />
                          </div>
                        )
                      ) : (
                        <img
                          src={cartIcon_redesign}
                          alt={"cart"}
                          className={"img-fluid"}
                          onClick={(e) => {
                            e.stopPropagation();
                            openQtyAndAddToCart();
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <p
                      className={"custome-md-view-more whitespace-nowrap mb-0"}
                      // onClick={() => redirectToDetailPage()}
                    >
                      View More{" "}
                      <span>
                        <img src={designviewmore} />
                      </span>
                    </p>
                  )}
                </>
              )}

              {/*{*/}
              {/*	!searchModal && isOrderPage && myReview === null &&*/}

              {/*	<div className={'d-flex cursor-pointer align-items-center'}*/}
              {/*	     onClick={() => setReviewAction(true)}>*/}
              {/*		<img src={pencilIconBlack} alt={"cart"} className={"img-fluid"}*/}
              {/*		/>*/}
              {/*		<p className={' euphemia-700-16  mb-2 ml-1 whitespace-nowrap'}>Review</p>*/}
              {/*	</div>*/}
              {/*}*/}
            </div>
          </div>
        </div>
      </div>
      {reviewAction && isOrderPage && myReview === null && (
        <StarModal1
          originalPrice={originalPrice}
          price={price}
          productid={productid}
          name={name}
          inlineid={inlineid}
          description={description}
          discountPercentage={discountPercentage}
          featuredImage={featuredImage}
          FeaturedProductImg={FeaturedProductImg}
          reviewAction={reviewAction}
          setReviewAction={setReviewAction}
          setShowAction={setShowAction}
          showAction={showAction}
        />
      )}
    </div>
  );
};

const StarModal1 = (props) => {
  const [starCount, setStarCount] = useState(0);
  const [comments, setComments] = useState("");

  const createReview = () => {
    let variable = {
      comment: comments,
      lineItemId: props.inlineid,
      productId: props.productid,
      rating: starCount,
    };
    createOrUpdateReviewMutation(
      variable,
      (response) => {
        if (response !== null) {
          cogoToast.success(
            <CustomToast
              type={"success"}
              message={response.createOrUpdateReview.message}
            />,
            { position: "top-center" }
          );
        } else {
          cogoToast.error(
            <CustomToast type={"error"} message={"Something went wrong"} />,
            { position: "top-center" }
          );
        }
        props.setReviewAction(false);
      },
      (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  return (
    <MDBModal isOpen={props.reviewAction} className={"custom_model_action"}>
      <MDBModalBody>
        <div
          className={"mobile-header w-100 d-flex  align-items-center px-4 py-3"}
        >
          <div className={"w-75 d-flex"}>
            <img
              src={close_redesign}
              alt={"alt"}
              className={"mr-2"}
              onClick={() => props.setReviewAction(false)}
            />
            <p className={"heading_model mb-0"}>Review Product</p>
          </div>
          <div className={"w-25 save"} onClick={() => createReview()}>
            Save
          </div>
        </div>
        <div className={"modal_middle_content d-flex"}>
          <div className={"left-side"}>
            <img
              src={props.featuredImage}
              className={"featured-image"}
              alt={"alt"}
            />
          </div>
          <div className={"right-side"}>
            <div>
              <LinesEllipsis
                text={props.name}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
            <div>
              <LinesEllipsis
                text={props.description}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
            <div>
              <div className={"d-flex mb-2 align-items-center"}>
                <p
                  className={"trade-con-700-20 fabelle-primary-brown mb-0 mr-2"}
                >
                  ₹ {props.variantPricePincodeBased??props.price}
                </p>
                {!props.variantPricePincodeBased && parseInt(props.discountPercentage) > 0 && (
                  <del
                    className={"trade-con-700-14 fabelle-primary-brown mb-0"}
                  >
                    ₹ {props.originalPrice}
                  </del>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={"modal_price"}>
          <div className={"modal_price_text mb-3"}>Rate the product</div>
          <div className={"d-flex w-100 justify-content-between"}>
            <img
              src={starCount >= 1 ? filled_star : empty_star}
              onClick={() => setStarCount(1)}
              alt={"empty_star"}
              className={"empty_star pointer"}
            />
            <img
              src={starCount >= 2 ? filled_star : empty_star}
              onClick={() => setStarCount(2)}
              alt={"empty_star"}
              className={"empty_star pointer"}
            />
            <img
              src={starCount >= 3 ? filled_star : empty_star}
              onClick={() => setStarCount(3)}
              alt={"empty_star"}
              className={"empty_star pointer"}
            />
            <img
              src={starCount >= 4 ? filled_star : empty_star}
              onClick={() => setStarCount(4)}
              alt={"empty_star"}
              className={"empty_star pointer"}
            />
            <img
              src={starCount >= 5 ? filled_star : empty_star}
              onClick={() => setStarCount(5)}
              alt={"empty_star"}
              className={"empty_star pointer"}
            />
          </div>
        </div>

        <div className={"modal_comments_parent"}>
          {comments.length > 0 && (
            <span>Your Comment (Maximum 200 characters)</span>
          )}
          <textarea
            className={"redesigned-input-style "}
            maxLength={200}
            onChange={(e) => setComments(e.target.value)}
            placeholder={"Your Comment (Maximum 200 characters)"}
          ></textarea>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = (state) => ({
  checkoutIdFromStore: state.CheckoutReducer.checkoutId,
  isCartOpen: state.CheckoutReducer.isCartOpen,
  cartItemFromStore: state.CheckoutReducer.cartItems
});

const mapDispatchToProps = (dispatch) => ({
  setCartModalStatusToStore: (data) => dispatch(setCartModalState(data)),
  setCartDataToStore: (data) => dispatch(updateCart(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCardHorizontal);
