/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollectionQueryVariables = {||};
export type CollectionQueryResponse = {|
  +collections: ?$ReadOnlyArray<?{|
    +id: string,
    +name: string,
    +image: ?string,
    +desktopBanner: ?string,
    +mobileBanner: ?string,
  |}>
|};
export type CollectionQuery = {|
  variables: CollectionQueryVariables,
  response: CollectionQueryResponse,
|};
*/


/*
query CollectionQuery {
  collections {
    id
    name
    image
    desktopBanner
    mobileBanner
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CollectionNode",
    "kind": "LinkedField",
    "name": "collections",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "image",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "desktopBanner",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mobileBanner",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CollectionQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3e0813ae3c8333a5813218f18735c937",
    "id": null,
    "metadata": {},
    "name": "CollectionQuery",
    "operationKind": "query",
    "text": "query CollectionQuery {\n  collections {\n    id\n    name\n    image\n    desktopBanner\n    mobileBanner\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '650c3e3ff7e6411b26b87f4612ce7f52';

module.exports = node;
