/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createOrUpdateReviewMutationVariables = {|
  comment: string,
  lineItemId: string,
  productId: string,
  rating: number,
|};
export type createOrUpdateReviewMutationResponse = {|
  +createOrUpdateReview: ?{|
    +status: ?string,
    +message: ?string,
  |}
|};
export type createOrUpdateReviewMutation = {|
  variables: createOrUpdateReviewMutationVariables,
  response: createOrUpdateReviewMutationResponse,
|};
*/


/*
mutation createOrUpdateReviewMutation(
  $comment: String!
  $lineItemId: ID!
  $productId: ID!
  $rating: Float!
) {
  createOrUpdateReview(comment: $comment, lineItemId: $lineItemId, productId: $productId, rating: $rating) {
    status
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "comment"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lineItemId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rating"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment"
      },
      {
        "kind": "Variable",
        "name": "lineItemId",
        "variableName": "lineItemId"
      },
      {
        "kind": "Variable",
        "name": "productId",
        "variableName": "productId"
      },
      {
        "kind": "Variable",
        "name": "rating",
        "variableName": "rating"
      }
    ],
    "concreteType": "CreateOrUpdateReviewMutation",
    "kind": "LinkedField",
    "name": "createOrUpdateReview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createOrUpdateReviewMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createOrUpdateReviewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "58aae3476b95f3f9769eba04e85ae7c3",
    "id": null,
    "metadata": {},
    "name": "createOrUpdateReviewMutation",
    "operationKind": "mutation",
    "text": "mutation createOrUpdateReviewMutation(\n  $comment: String!\n  $lineItemId: ID!\n  $productId: ID!\n  $rating: Float!\n) {\n  createOrUpdateReview(comment: $comment, lineItemId: $lineItemId, productId: $productId, rating: $rating) {\n    status\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aae8425944cf56112b75c3216103a1e9';

module.exports = node;
