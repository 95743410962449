import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../environment'

const mutation = graphql`
 mutation LoginWithMobileMutation($mobileNumber:String!){
    loginWithMobile(mobileNumber:$mobileNumber){
        message
    }
 }
`

export default (data, callback, errCallback) => {
	const variables = {
		mobileNumber: data.mobileNumber,

	};

	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.loginWithMobile !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			},
		},
	)
}

