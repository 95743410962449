/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CheckoutLineAddMutationVariables = {|
  checkoutId: string,
  quantity: number,
  variantId: string,
|};
export type CheckoutLineAddMutationResponse = {|
  +checkoutLineAdd: ?{|
    +checkout: ?{|
      +id: string,
      +cartItems: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +quantity: number,
            +variant: ?{|
              +id: string,
              +getPrice: ?string,
              +getOriginalPrice: ?string,
              +featuredImage: ?string,
              +discountedPrice: ?string,
              +discountPercentage: ?string,
              +sku: string,
              +isAvailable: ?boolean,
              +variantId: ?string,
              +product: {|
                +rating: ?number,
                +name: string,
                +description: ?string,
                +price: any,
                +discountedPrice: ?any,
                +collection: ?{|
                  +name: string
                |},
              |},
            |},
          |}
        |}>
      |},
      +totalAmount: ?number,
      +discount: ?number,
      +voucherCode: ?string,
      +getActualTotal: ?number,
      +offerDiscount: ?number,
      +shippingPrice: ?number,
      +totalWithShipping: ?number,
      +confettiAmount: ?number,
      +user: ?{|
        +username: ?string
      |},
    |}
  |}
|};
export type CheckoutLineAddMutation = {|
  variables: CheckoutLineAddMutationVariables,
  response: CheckoutLineAddMutationResponse,
|};
*/


/*
mutation CheckoutLineAddMutation(
  $checkoutId: String!
  $quantity: Int!
  $variantId: String!
) {
  checkoutLineAdd(checkoutId: $checkoutId, quantity: $quantity, variantId: $variantId) {
    checkout {
      id
      cartItems {
        edges {
          node {
            id
            quantity
            variant {
              id
              getPrice
              getOriginalPrice
              featuredImage
              discountedPrice
              discountPercentage
              sku
              isAvailable
              variantId
              product {
                rating
                name
                description
                price
                discountedPrice
                collection {
                  name
                  id
                }
                id
              }
            }
          }
        }
      }
      totalAmount
      discount
      voucherCode
      getActualTotal
      offerDiscount
      shippingPrice
      totalWithShipping
      confettiAmount
      user {
        username
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "checkoutId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "quantity"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "variantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "checkoutId",
    "variableName": "checkoutId"
  },
  {
    "kind": "Variable",
    "name": "quantity",
    "variableName": "quantity"
  },
  {
    "kind": "Variable",
    "name": "variantId",
    "variableName": "variantId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "getPrice",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "getOriginalPrice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "featuredImage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountedPrice",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPercentage",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAvailable",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "variantId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalAmount",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discount",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voucherCode",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "getActualTotal",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerDiscount",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingPrice",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalWithShipping",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confettiAmount",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutLineAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CheckoutLineAddOrUpdate",
        "kind": "LinkedField",
        "name": "checkoutLineAdd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CartNode",
            "kind": "LinkedField",
            "name": "checkout",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartItemNodeConnection",
                "kind": "LinkedField",
                "name": "cartItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CartItemNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CartItemNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductVariantNode",
                            "kind": "LinkedField",
                            "name": "variant",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductNode",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CollectionNode",
                                    "kind": "LinkedField",
                                    "name": "collection",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v24/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckoutLineAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CheckoutLineAddOrUpdate",
        "kind": "LinkedField",
        "name": "checkoutLineAdd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CartNode",
            "kind": "LinkedField",
            "name": "checkout",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartItemNodeConnection",
                "kind": "LinkedField",
                "name": "cartItems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CartItemNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CartItemNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductVariantNode",
                            "kind": "LinkedField",
                            "name": "variant",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductNode",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CollectionNode",
                                    "kind": "LinkedField",
                                    "name": "collection",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v24/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c9ab94098d5735294e70ca58b3ca7919",
    "id": null,
    "metadata": {},
    "name": "CheckoutLineAddMutation",
    "operationKind": "mutation",
    "text": "mutation CheckoutLineAddMutation(\n  $checkoutId: String!\n  $quantity: Int!\n  $variantId: String!\n) {\n  checkoutLineAdd(checkoutId: $checkoutId, quantity: $quantity, variantId: $variantId) {\n    checkout {\n      id\n      cartItems {\n        edges {\n          node {\n            id\n            quantity\n            variant {\n              id\n              getPrice\n              getOriginalPrice\n              featuredImage\n              discountedPrice\n              discountPercentage\n              sku\n              isAvailable\n              variantId\n              product {\n                rating\n                name\n                description\n                price\n                discountedPrice\n                collection {\n                  name\n                  id\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n      totalAmount\n      discount\n      voucherCode\n      getActualTotal\n      offerDiscount\n      shippingPrice\n      totalWithShipping\n      confettiAmount\n      user {\n        username\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2514ff1e23e348c37162a4b732d16e62';

module.exports = node;
